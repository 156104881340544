
import React from "react";
import { Link } from "react-router-dom";
import CustomButton from "./CustomButton";

const DiseasesCard = (props) => {

  const { itemData } = props


  return (
    <>
      <div className="rounded overflow-hidden shadow-lg custom-card-1 hover:bg-gray-50">
        <div className="card-image bg-white text-center py-5">
          {
            itemData?.image && (
              <>
                <img
                  alt={itemData?.name}
                  src={itemData?.imageUrl}
                  style={{
                    width: 80,
                    height: 80,
                    margin: '0 auto'
                  }}
                />
                {/* <ImageLoader
                    srcLink={removeImageName(itemData?.imageUrl, itemData?.image)}
                    src={itemData?.image}
                    alt="Picture of the author"
                    width={80}
                    height={80}
                /> */}
              </>
            )
          }
        </div>
        <hr className="hr" />
        <div className="card-content pt-2 p-4 pb-0">
          <h3 className="text-lg">
            <a className="no-underline hover:underline text-sky-500 sm:text-sm font-bold" href="#0">
              {itemData?.name}
            </a>
          </h3>

          <div className="w-full text-gray-900 ">
            <div className="inline-flex relative items-center py-2 w-full text-md font-bold ">
              <svg
                aria-hidden="true"
                className="mr-2 w-4 h-4 fill-current"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z"></path>
              </svg>
              {itemData?.test_count}+ Tests
            </div>

          </div>
        </div>
        <div className="card-footer unset sm:flex p-5 pt-0">
          <Link to={`./tests/${itemData?.name}`}>
            <CustomButton variant="outlined">View Detail</CustomButton>
            {/* <button className="border-2 border-sky-500  my-2 sm:mr-4 hover:bg-sky-500 hover:text-white text-sky-500 font-bold py-2 rounded-full w-full">
              View Detail
            </button> */}
          </Link>
        </div>
      </div>
    </>
  );
};

export default DiseasesCard;
