import React, { useEffect, useState } from 'react'
import SubHeaderSection from '../components/SubHeaderSection'
import { useDispatch, useSelector } from 'react-redux';
import { singlePackageDataAPI } from '../redux/Slice/PackagePageSlice';
import SectionComponent from '../components/SectionComponent';
import PackageCard from '../components/PackageCard';
import { useNavigate, useParams } from 'react-router-dom';
import AddToCartButton from '../components/AddToCartButton';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab, Box } from '@mui/material';

const PackagesDetailPage = () => {


  const [value, setValue] = React.useState(1);
  const [TestValue, setTestValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeTest = (event, newValue) => {
    setTestValue(newValue);
  };


  const params = useParams();
  let slug = params.slug;
  const { data, loading, singleData } = useSelector((state) => state.PackagePage);

  const dispatch = useDispatch();
  const [returnData, setReturnData] = useState(null)
  const [ItemData, setItemData] = useState({});

  useEffect(() => {
    dispatch(singlePackageDataAPI({ slug }));
  }, [slug])
  useEffect(() => {

    if (!loading) {
      setReturnData(singleData.data);
      setItemData(singleData.data?.item_data[0])
    }

  }, [loading])

  const bookNow = () => {

  }

  return (
    <>
      <SubHeaderSection title={returnData ? returnData.title : "Packages"} />
      <SectionComponent >
        <div className="flex items-center gap-4">
          <div className="priceSec">
            <div className="a_price text-4xl font-bold text-green-600">
              ₹{ItemData?.price}
            </div>
            <div className="italic a_mrp text-2xl font-semibold text-red-700 line-through">
              ₹{ItemData?.mrp}
            </div>
          </div>
          <div className="report-download ml-8 justify-between flex flex-col">
            <div className="">
              <button
                type="button"
                className="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-gray hover:text-white hover:bg-indigo-700 border-indigo-600 "
              >
                <svg
                  aria-hidden="true"
                  className="mr-2 w-4 h-4 fill-current"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M2 9.5A3.5 3.5 0 005.5 13H9v2.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 15.586V13h2.5a4.5 4.5 0 10-.616-8.958 4.002 4.002 0 10-7.753 1.977A3.5 3.5 0 002 9.5zm9 3.5H9V8a1 1 0 012 0v5z"
                    clipRule="evenodd"
                  ></path>
                </svg>{" "}
                Download Sample Report
              </button>
            </div>
            <div className="mt-1 font-semibold flex items-center">
              <svg
                aria-hidden="true"
                className="mr-2 w-4 h-4 fill-current"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z"></path>
              </svg>
              {ItemData?.test_count}+ Test
            </div>
            <div>
              {/* {router.query.type === "promotion" ? (
                  <button
                    onClick={() => bookNow(ItemData)}
                    className="border-2 bg-sky-500  my-2 sm:mr-4 hover:bg-sky-600 hover:text-white text-white font-bold py-2 rounded-full w-full"
                  >
                    Book Now
                  </button>
                ) : (
                  <AddToCartButton itemId={ItemData?.id} type="package" />
                )} */}
              <AddToCartButton itemId={ItemData?.id} type="package" />
            </div>
          </div>
        </div>
        <div className="mt-3">
          <h3 className="mb-3 pb-2 text-2xl font-bold border-b-2 border-gray-300 ">
            Package Detail
          </h3>
          <div dangerouslySetInnerHTML={{ __html: ItemData?.description }} />
        </div>

        {ItemData?.profiles && ItemData?.profiles.length > 0 && (
          <>
            <div className="mt-5"></div>
            <div className="mt-3">
              <h3 className="mb-3 pb-2 text-2xl font-bold border-b-2 border-gray-300 ">
                Profiles
              </h3>
              <div>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                      variant="scrollable"
                      scrollButtons="auto"
                      allowScrollButtonsMobile
                    >
                      {ItemData?.profiles.map((item, i) => {
                        return (
                          <Tab key={i} label={item?.name} value={i + 1} />
                        );
                      })}
                      {/* <Tab label="Profile 2" value="2" /> */}
                    </TabList>
                  </Box>

                  {ItemData?.profiles.map((item, i) => {
                    return (
                      <TabPanel key={i} value={i + 1} sx={{
                        paddingX: 0
                      }}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item?.description,
                          }}
                        />
                        <div>
                          {item?.tests && item?.tests.length > 0 && (
                            <div className="mt-3">
                              <h3 className="mb-3 pb-2 text-2xl font-bold border-b-2 border-gray-300 ">
                                Tests On Profile
                              </h3>

                              <div>
                                <div className="flex">
                                  <ul className="listGroup">
                                    {item?.tests.map((item0, i) => {
                                      return (
                                        <>
                                          <li key={i} className="listItem">
                                            {item0?.name}
                                          </li>
                                        </>
                                      );
                                    })}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </TabPanel>
                    );
                  })}
                </TabContext>
              </div>
            </div>
          </>
        )}

        <div className="mt-5"></div>
        {ItemData?.tests && ItemData?.tests.length > 0 && (
          <div className="mt-3">
            <h3 className="mb-3 pb-2 text-2xl font-bold border-b-2 border-gray-300 ">
              Tests On Package
            </h3>

            <div>
              <div className="flex">
                <ul className="listGroup">
                  {ItemData?.tests.map((item, i) => {
                    return (
                      <>
                        <li key={i} className="listItem">
                          {item?.name}
                        </li>
                      </>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        )}

      </SectionComponent>
    </>
  )
}

export default PackagesDetailPage