import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../constants/axiosInstance";
import { getCrtToken } from "../../constants/randomFunctions";


export const testDataAPI = createAsyncThunk(
  'testData',
  async (params = {}, thunkAPI) => {
    const response = await axiosInstance(getCrtToken).post(
      "/tests-list",
      params
    )
    return response.data
  }
)

export const testCategoryDataAPI = createAsyncThunk(
  'testCategoryData',
  async (params = {}, thunkAPI) => {
    const response = await axiosInstance(getCrtToken).post(
      "/test-category-list",
      params
    )
    return response.data
  }
)

const TestPageSlice = createSlice({
  name: "TestPage",
  initialState: {
    loading: true,
    categoryLoading: true,
    data: [],
    categoryData: [],
    error: null,
    message: null,
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(testDataAPI.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    })
    builder.addCase(testCategoryDataAPI.fulfilled, (state, action) => {
      state.categoryLoading = false;
      state.categoryData = action.payload;
    })
  },
})

export const {
  getTestData
} = TestPageSlice.actions;

export default TestPageSlice.reducer;