import React, { useEffect, useState } from 'react'
import SubHeaderSection from '../components/SubHeaderSection'
import { useDispatch, useSelector } from 'react-redux';
import { packageDataAPI } from '../redux/Slice/PackagePageSlice';
import SectionComponent from '../components/SectionComponent';
import PackageCard from '../components/PackageCard';
import { CircularProgress } from '@mui/material';
import { ISO_14001_2015, ISO_45001_2018, ISO_9001_2015 } from '../images';
import { pageDataAPI } from '../redux/Slice/PagesSlice';

const TermsConditionsPage = () => {

    const {data, loading} = useSelector(state=>state.pages);
    const [Pages, setPages] = useState(null)
    const dispatch = useDispatch()
    useEffect(() => {
      if(loading){
        dispatch(pageDataAPI())
      }
      if(!loading){
        console.log('data >>> ', data.data)
        let crtPage = data.data.find(item=>item.id === 1);
        setPages(crtPage)
      }
    }, [loading])
    


    return (
        <>
            <SubHeaderSection title={"Terms & Conditions"} />
            <SectionComponent >
                <div className="p-3 sm:p-5 grid grid-cols-1 gap-5 cst-text">
                <div dangerouslySetInnerHTML={{__html: (Pages?.description || "")}} />
                    
                    
                </div>
            </SectionComponent>
        </>
    )
}

export default TermsConditionsPage