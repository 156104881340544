import React, { useEffect, useState } from 'react'
import SubHeaderSection from '../components/SubHeaderSection'
import { useDispatch, useSelector } from 'react-redux';
import SectionComponent from '../components/SectionComponent';
import PackageCard from '../components/PackageCard';
import { Chip, CircularProgress, Pagination } from '@mui/material';
import { testCategoryDataAPI, testDataAPI } from '../redux/Slice/TestPageSlice';
import TestCardComponent from '../components/TestCardComponent';
import axiosInstance from '../constants/axiosInstance';
import { useParams } from 'react-router-dom';

const TestsPage = () => {
    const {
        data, loading, categoryData, categoryLoading
    } = useSelector((state) => state.TestPage)

    const { slug } = useParams();

    const [page, setPage] = React.useState(1);
    const [PageLimit, setPageLimit] = React.useState(10);
    const [ActiveCategory, setActiveCategory] = React.useState('all');
    const [FilterData, setFilterData] = useState([]);
    const [FilterData1, setFilterData1] = useState([]);

    const [PageLength, setPageLength] = React.useState(0);

    const handleChange = (event, value) => {
        setPage(value);
        filteredDataByPages(value, [])
    };


    const filteredDataByPages = (page0 = 1, allData = []) => {
        let start = (((page0 - 1) * PageLimit));
        let ffData = allData;

        if (allData && allData.length == 0) {
            ffData = FilterData;
        }

        let fData = ffData.slice(start, (page0 * PageLimit))
        // console.log(start, ffData);
        setFilterData1(fData)
    }



    const handleClick = (item0) => {
        // console.log('handleClick >>>> ');

        setPage(1);
        if (item0 !== null) {
            let data0 = returnData?.item_data.filter(item => item.test_category_id == item0.id);
            let a0 = Math.ceil(data0.length / PageLimit);
            setPageLength(a0)
            setFilterData(data0)
            filteredDataByPages(page, data0)
            setActiveCategory(item0.id)
        } else {
            let data0 = returnData?.item_data;
            let a0 = Math.ceil(data0.length / PageLimit);
            setPageLength(a0)
            setFilterData(data0)
            filteredDataByPages(page, data0)
            setActiveCategory("all")
        }
    }


    const dispatch = useDispatch();

    const [returnData, setReturnData] = useState(null)
    const [testCategoriesData, setTestCategoriesData] = useState({})

    // let testCategoriesData = {};
    // const getTestCategory = async () => {
    //     await axiosInstance
    //         .post('/test-category-list', {}, {
    //             //   headers: headers
    //         }).then(red0 => {
    //             // console.log('red0.da', red0.data)
    //             //   red0.data;
    //             setTestCategoriesData(red0.data);
    //             //   testCategoriesData = red0.data
    //         })
    //         .catch((error) => {
    //             // console.log('error <><>', error)
    //         });
    // }
    useEffect(() => {
        if (categoryLoading) {
            dispatch(testCategoryDataAPI());
        }
        if (!categoryLoading) {
            setTestCategoriesData(categoryData);
        }
    }, [categoryLoading])

    useEffect(() => {
        if (loading) {
            dispatch(testDataAPI());
        }
        if (!loading) {
            let data0 = data.data?.item_data;
            let a0 = Math.ceil(data0.length / PageLimit);
            setPageLength(a0)
            setFilterData(data0)
            filteredDataByPages(page, data0)
            setReturnData(data.data);
            // setTestCategoriesData(categoryData);

        }
    }, [loading])

    useEffect(() => {
        if (!categoryLoading && !loading) {
            console.log('obje....ct', testCategoriesData)
            if (slug && testCategoriesData?.data && testCategoriesData?.data.length > 0) {
                let a0 = testCategoriesData.data.find(item => item.name === slug)
                console.log(a0, slug)
                handleClick(a0)
            }
        }
    }, [slug, loading, categoryLoading, testCategoriesData])



    return (
        <>
            <SubHeaderSection title={returnData ? returnData.title : "All Tests"} />
            {
                loading ?
                    <CircularProgress />
                    :
                    <SectionComponent >
                        <div className='mx-auto' style={{ minHeight: "70vh", maxWidth: '900px' }}>
                            <div className="grid grid-cols-1 w-full gap-4  mb-5">

                                <div className='flex flex-wrap gap-x-2 gap-y-2'>

                                    <Chip label={"All"} className={ActiveCategory == 'all' ? 'chipsTab' : ""} variant={ActiveCategory == 'all' ? "container" : "outlined"} onClick={(e) => handleClick(null)} />

                                    {
                                        testCategoriesData?.data && testCategoriesData?.data.length && testCategoriesData?.data.map((item, i) => {
                                            return (
                                                <React.Fragment key={i}>
                                                    <Chip label={item.name} className={ActiveCategory == item.id ? 'chipsTab' : ""} variant={ActiveCategory == item.id ? "container" : "outlined"} onClick={(e) => handleClick(item)} />
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </div>

                            </div>

                            <div className="sm:flex items-start  gap-4">

                                <div className="grid grid-cols-1 w-full gap-4">
                                    {
                                        FilterData1 && FilterData1?.map((item, i) => {
                                            return (
                                                <TestCardComponent key={i} itemData={item} />
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <div className="sm:flex items-start  gap-4  mt-5">
                                <div className="grid grid-cols-1 w-full gap-4 mx-auto">
                                    <Pagination count={PageLength} page={page} onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                    </SectionComponent>
            }
        </>
    )
}

export default TestsPage