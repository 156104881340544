import React, { useEffect, useState } from 'react'
import SubHeaderSection from '../components/SubHeaderSection'
import { useDispatch, useSelector } from 'react-redux';
import { packageDataAPI } from '../redux/Slice/PackagePageSlice';
import SectionComponent from '../components/SectionComponent';
import PackageCard from '../components/PackageCard';
import { CircularProgress } from '@mui/material';
import { ISO_14001_2015, ISO_45001_2018, ISO_9001_2015 } from '../images';
import { pageDataAPI } from '../redux/Slice/PagesSlice';

const ShippingDeliveryPage = () => {

    const {data, loading} = useSelector(state=>state.pages);
    const [Pages, setPages] = useState(null)
    const dispatch = useDispatch()
    useEffect(() => {
      if(loading){
        dispatch(pageDataAPI())
      }
      if(!loading){
        let crtPage = data.data.find(item=>item.id === 3);
        setPages(crtPage)
      }
    }, [loading])


    return (
        <>
            <SubHeaderSection title={"Shipping Delivery"} />
            <SectionComponent >
                <div className="p-3 sm:p-5 grid grid-cols-1 gap-5 cst-text">
                    
                <div dangerouslySetInnerHTML={{__html: (Pages?.description || "")}} />
                    {/* <div>

                
                    <p>A shipping and delivery policy contains information about rules, timelines, and processes for shipped items.</p>

                    <p>Details required on page:</p>
                    <ul style={{
                        listStyle: 'revert'
                    }}>
                        <li>Order processing and shipping the </li>
                        <li>Shipping costs (if any)</li>
                        <li>International shipping process (if applicable)</li>
                    </ul>
                    </div> */}
                </div>
            </SectionComponent>
        </>
    )
}

export default ShippingDeliveryPage