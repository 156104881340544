import React from 'react'
import HeaderComponent from '../components/layout/HeaderComponent'
import FooterComponent from '../components/layout/FooterComponent'
import { Outlet } from 'react-router-dom'

const Layout = () => {
  return (
    <>
      <HeaderComponent />
        <Outlet />
      <FooterComponent />
    </>
  )
}

export default Layout
