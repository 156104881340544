import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../constants/axiosInstance";
import { getCrtToken } from "../../constants/randomFunctions";


export const cartDataAPI = createAsyncThunk(
    'cartData',
    async (params = {}, thunkAPI) => {
        const response = await axiosInstance(getCrtToken).post(
            "/customer/cart/item",
            params
        )
        return response?.data
    }
)

const CartPageSlice = createSlice({
    name: "CartPage",
    initialState: {
        data: [],
        cartDetail: {},
        loading: true,
        msg: "",
        error: "",
        msgType: "",
    },
    reducers: {
        cartDataEmpty: (state, action) => {
            state.data = [];
            state.loading = true;
            state.msg = "";
            state.msgType = "";
        },
    },
    extraReducers: (builder) => {

        builder.addCase(cartDataAPI.pending, (state, action) => {
            state.loading = true;
            state.error = false;
            state.msgType = null;
        });
        builder.addCase(cartDataAPI.fulfilled, (state, action) => {
            state.data = action.payload?.data;
            state.cartDetail = action.payload?.cartDetail;
            state.loading = action.payload?.loading;
            state.msg = action.payload?.msg;
            state.msgType = action.payload?.msgType;
        });
        builder.addCase(cartDataAPI.rejected, (state, action) => {
            state.loading = false;
            state.error = true;
            state.msgType = "error";
            state.msg = action.payload?.msg;
            state.data = action.payload;
        });
    },
})

export const {
    cartDataEmpty
} = CartPageSlice.actions;

export default CartPageSlice.reducer;