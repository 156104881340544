import React, { useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { dashboardDataAPI } from "../../redux/Slice/DashboardPageSlice";
import { Box, Button, Card, CardActions, CardContent, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import InputComponent from "../../components/form/InputComponent";
import { useForm } from "react-hook-form";
import { userProfileUpdateAPI } from "../../redux/Slice/UserProfilePageSlice";

const UserProfilePage = () => {
  let cardArr = [];

  const { data, loading, isAuth } = useSelector((state) => state.UserProfilePage);

  const dispatch = useDispatch();

  const [returnData, setReturnData] = useState(null);
  const [crtUser, setCrtUser] = useState({});


  // useEffect(() => {
  //   if (loading) {
  //     dispatch(dashboardDataAPI());
  //   }
  //   if (!loading) {
  //     setReturnData(data.data);
  //   }
  // }, [loading]);


  const navigate = useNavigate();

  // let crtUser = {};

  const { control, formState: { errors }, handleSubmit, reset } = useForm({
    // defaultValues: {
    //   full_name: crtUser?.name,
    //   email: crtUser?.email,
    //   mobile: crtUser?.mobile,
    //   mobile_2: crtUser?.mobile2,
    //   address: crtUser?.address,
    //   landmark: crtUser?.landmark,
    //   locality: crtUser?.locality,
    //   pin_code: crtUser?.pin,
    //   state: crtUser?.state,
    //   city: crtUser?.city,
    // }
  });


  const onSubmit = data => {
    dispatch(userProfileUpdateAPI(data));
  };
  useEffect(() => {
    if (!loading) {
      let a00 = data?.data;
      setCrtUser(data?.data);

      let a0 = {
        full_name: a00?.name,
        email: a00?.email,
        mobile: a00?.mobile,
        mobile_2: a00?.mobile2,
        address: a00?.address,
        landmark: a00?.landmark,
        locality: a00?.locality,
        pin_code: a00?.pin,
        state: a00?.state,
        city: a00?.city,
      }
      reset(a0)
    }
  }, [loading]);
  useEffect(() => {

  }, [crtUser]);




  return (
    <>
      <div className="">
        <div className="w-full">
          <form onSubmit={handleSubmit(onSubmit)} >
            <div className="grid gap-6 mb-6 md:grid-cols-2">
              <div>
                <InputComponent
                  label="Full Name"
                  name="full_name"
                  type="text"
                  placeholder="John"
                  errors={errors?.full_name}
                  value={crtUser?.name}
                  control={control}
                  aria-invalid={"true"}
                  rules={{ required: "This Field is required!" }}
                />
              </div>


              <div>
                <InputComponent
                  label="Email Address"
                  type="email"
                  name="email"
                  value={crtUser?.email}
                  id="email"
                  placeholder="john.doe@company.com"
                  control={control}
                  errors={errors?.email}
                  rules={{ required: true }}
                />
              </div>

              <div>

                <InputComponent
                  label="Mobile number"
                  name="mobile"
                  type="text"
                  value={crtUser?.mobile}
                  placeholder="123-45-678"
                  // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                  control={control}
                  errors={errors?.mobile}
                  rules={{ required: true }}
                />

              </div>

              <div>

                <InputComponent
                  label="Alter Native/ WhatsApp Number"
                  name="mobile_2"
                  value={crtUser?.mobile2}
                  type="text"
                  placeholder="123-45-678"
                  control={control}
                />
              </div>


              <div>


                <InputComponent
                  label="Address"
                  name="address"
                  value={crtUser?.address}
                  type="text"
                  placeholder="Address"
                  control={control}
                />


              </div>
              <div>

                <InputComponent
                  label="Landmark"
                  name="landmark"
                  value={crtUser?.landmark}
                  type="text"
                  placeholder="Landmark"
                  control={control}
                />


              </div>
              <div>

                <InputComponent
                  label="Locality"
                  name="locality"
                  value={crtUser?.locality}
                  type="text"
                  placeholder="Locality"
                  control={control}
                />

              </div>
              <div>
                <InputComponent
                  label="Pin Code"
                  name="pin_code"
                  value={crtUser?.pin}
                  type="text"
                  placeholder="Pin Code"
                  control={control}
                />
              </div>

              <div>

                <InputComponent
                  label="State"
                  name="state"
                  value={crtUser?.state}
                  type="text"
                  placeholder="State"
                  control={control}
                />
              </div>
              <div>

                <InputComponent
                  label="City"
                  name="city"
                  value={crtUser?.city}
                  type="text"
                  placeholder="City"
                  control={control}
                />
              </div>



            </div>

            <CustomButton type="submit">Update</CustomButton>

            {/* <button
              type="submit"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Submit
            </button> */}

          </form>
        </div>

      </div>
    </>
  );
};

export default UserProfilePage;
