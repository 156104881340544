import { BrowserRouter, Route, Routes } from "react-router-dom";

import Frontend from "./pages/Index";
import UserFrontend from "./pages/user/Index";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { createTheme, Button, ThemeProvider, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cartDataAPI } from "./redux/Slice/CartPageSlice";
import { getCartListParams } from "./constants/randomFunctions";
import { userProfileDataAPI } from "./redux/Slice/UserProfilePageSlice";
import ProtectedRoute from "./ProtectedRoute";
import RazorpayComponent from "./pages/RazorpayComponent";
import LoadingComponent from "./components/LoadingComponent";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: "#0ea5e9",
        darker: "#053e85",
      },
      // secondary: {
      //   // This is green.A700 as hex.
      //   main: '#11cb5f',
      // },
    },
  });

  const { isAuth } = useSelector((state) => state.UserProfilePage);

  const { data: cartData, loading: cartLoading } = useSelector(
    (state) => state.CartPage
  );

  const { data: userData, loading: userLoading } = useSelector(
    (state) => state.UserProfilePage
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(cartDataAPI(getCartListParams));
    dispatch(userProfileDataAPI());
  }, []);
  useEffect(() => {
    // console.log("isAuth >>>> ", isAuth);
  }, [isAuth]);


  // useEffect(() => {
  //   // console.log("alllll >>> ", alllll);
  //   // console.log("cartData >>>>>", cartLoading, cartData);
  // }, [cartLoading]);

  

  if(userLoading){
    return <LoadingComponent />
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <BrowserRouter basename={"/"}>
            <Routes>
              <Route path="" element={<Frontend.Layout />}>
                <Route index element={<Frontend.HomePage />} />
                <Route
                  exact
                  path="/RazorpayComponent"
                  element={<RazorpayComponent />}
                />
                <Route
                  exact
                  path="/packages"
                  element={<Frontend.PackagesPage />}
                />
                <Route
                  exact
                  path="/packages/:slug"
                  element={<Frontend.PackagesDetailPage />}
                />
                <Route exact path="/tests" element={<Frontend.TestsPage />} />
                <Route
                  exact
                  path="/tests/:slug"
                  element={<Frontend.TestsPage />}
                />
                <Route
                  exact
                  path="/ourlabs"
                  element={<Frontend.OurLabsPage />}
                />
                <Route
                  exact
                  path="/contact-us"
                  element={<Frontend.ContactUsPage />}
                />
                <Route
                  exact
                  path="/make-payment"
                  element={<Frontend.MakePayment />}
                />
                <Route exact path="/blogs" element={<Frontend.BlogsPage />} />
                <Route exact path="/terms-conditions" element={<Frontend.TermsConditionsPage />} />
                <Route exact path="/privacy-policy" element={<Frontend.PrivacyPolicyPage />} />
                <Route exact path="/payment-refund-policy" element={<Frontend.PaymentRefundPolicyPage />} />
                <Route exact path="/shipping-delivery" element={<Frontend.ShippingDeliveryPage />} />
                <Route exact path="/disclaimer" element={<Frontend.DisclaimerPage />} />
                <Route
                  exact
                  path="/blogs/:slug"
                  element={<Frontend.BlogDetailPage />}
                />
                <Route exact path="/cart" element={<Frontend.Cart_Page />} />
              </Route>

              <Route
                path=""
                element={
                  <ProtectedRoute>
                    <Frontend.Layout />
                  </ProtectedRoute>
                }
              >
                <Route
                  exact
                  path="/cart/thank-you"
                  element={<Frontend.Cart_ThankYouPage />}
                />
                <Route
                  exact
                  path="/cart/checkout"
                  element={<Frontend.Cart_CheckOutPage />}
                />
              </Route>

              <Route path="" element={<Frontend.Layout />}>
                <Route exact path="/login" element={<Frontend.LoginPage />} />
                <Route
                  exact
                  path="/about-us"
                  element={<Frontend.AboutUsPage />}
                />
              </Route>

              <Route
                path="/account"
                element={
                  <ProtectedRoute>
                    <Frontend.UserLayout />
                  </ProtectedRoute>
                }
              >
                <Route index element={<UserFrontend.UserDashboardPage />} />
                <Route
                  exact
                  path="my-booking"
                  element={<UserFrontend.UserBookingPage />}
                />
                <Route
                  exact
                  path="profile"
                  element={<UserFrontend.UserProfilePage />}
                />
                <Route
                  exact
                  path="my-booking/detail/:id"
                  element={<UserFrontend.UserBookingDetailPage />}
                />
              </Route>
            </Routes>
          </BrowserRouter>
        </HelmetProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
