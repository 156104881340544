import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../constants/axiosInstance";
import { getCrtToken } from "../../constants/randomFunctions";


export const bookingDataAPI = createAsyncThunk(
  'bookingData',
  async (params = {}, thunkAPI) => {
    const response = await axiosInstance(getCrtToken).post(
      "/customer/my-bookings",
      params
    )
    return response.data
  }
)


const BookingPageSlice = createSlice({
  name: "BookingPage",
  initialState: {
    loading: true,
    data: [],
    error: null,
    msgType: null,
    msg: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Booking OR Register 
    builder.addCase(bookingDataAPI.pending, (state, action) => {
      state.loading = true;
      state.msgType = null;
      state.error = false;
    });
    builder.addCase(bookingDataAPI.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.msgType = "success";
      state.msg = action.payload?.msg;
      state.data = action.payload;
    });
    builder.addCase(bookingDataAPI.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.msgType = "error";
      state.msg = action.payload?.msg;
      state.data = action.payload;
    });


  },
})

export const {
  getBookingData
} = BookingPageSlice.actions;

export default BookingPageSlice.reducer;