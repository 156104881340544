import React from 'react'
import AddToCartButton from './AddToCartButton';

const TestCardComponent = (props) => {
  const { itemData } = props;
  return (
    <>
      <div className="test-item rounded overflow-hidden shadow-lg p-5 hover:bg-gray-50">
        <div className="card-content">
          <h3 className="text-lg">
            <a className="no-underline hover:underline text-black" href="#">
              {itemData?.name ? itemData?.name : ""}
            </a>
          </h3>
          <div className="w-full text-gray-900 ">
            <div className="inline-flex relative items-center py-2 w-full text-sm font-medium ">
              <svg aria-hidden="true" className="mr-2 w-4 h-4 fill-current" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z"></path></svg>
              1 Test
            </div>
          </div>
        </div>
        <div>
          <div className="card-price">
            <h6 className="text-2xl font-bold">
              ₹ {itemData?.price || 0}
            </h6>
          </div>
          <div className="card-footer unset sm:flex">
            <AddToCartButton itemId={itemData?.id} type="test" extClassName={`px-5`}/>
          </div>
        </div>
      </div>
    </>
  )
}

export default TestCardComponent