import React, { useEffect, useState } from "react";

const AlertComponent = (props) => {
  const {
    msgType,
    msg
  } = props;

  const [Type, setType] = useState('')
  useEffect(() => {

    switch (msgType) {
      case 'success':
        setType('teal')
        break;
      case 'error':
        setType('red')
        break;
      case 'info':
        setType('${Type}')
        break;
      case 'warning':
        setType('yellow')
        break;

      default:
        break;
    }
  }, [props]);


  if (msgType === '' &&
    msg === '') {
    return
  }

  return (
    <>
      <div
        id="alert-border-1"
        className={`flex p-4 mb-4 bg-${Type}-100 border-t-4 border-${Type}-500 dark:bg-${Type}-200`}
        role="alert"
      >
        <svg
          className={`flex-shrink-0 w-5 h-5 text-${Type}-700`}
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clipRule="evenodd"
          ></path>
        </svg>
        <div className={`ml-3 text-sm font-medium text-${Type}-700`}>
          <span dangerouslySetInnerHTML={{ __html: msg }} ></span>
        </div>
        {/* <button
          type="button"
          className={`ml-auto -mx-1.5 -my-1.5 bg-${Type}-100 dark:bg-${Type}-200 text-${Type}-500 rounded-lg focus:ring-2 focus:ring-${Type}-400 p-1.5 hover:bg-${Type}-200 dark:hover:bg-${Type}-300 inline-flex h-8 w-8`}
          data-dismiss-target="#alert-border-1"
          aria-label="Close"
        >
          <span className="sr-only">Dismiss</span>
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button> */}
      </div>
    </>
  );
};

export default AlertComponent;
