import { CircularProgress } from '@mui/material'
import React from 'react'

const LoadingComponent = () => {
  return (
    <div className='loading-page'>
        <CircularProgress />
    </div>
  )
}

export default LoadingComponent
