import React, { useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, ButtonGroup, Card, CardActions, CardContent, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { bookingDataAPI } from "../../redux/Slice/BookingPageSlice";
import MyBookingTable from "./MyBookingTable";

const UserBookingPage = () => {
  let cardArr = [];

  const { data, loading } = useSelector((state) => state.BookingPage);

  const dispatch = useDispatch();

  const [returnData, setReturnData] = useState(null);

  const [FilterButtonActive, setFilterButtonActive] = useState("0");
  const [TableHead, setTableHead] = useState([]);
  const [TableRows, setTableRows] = useState([]);

  const filterButtons = [
    {
      name: "All",
      value: "0",
      is_cancel: "0",
    },
    // {
    //   name: "Completed",
    //   value: "3",
    //   is_cancel: "0",
    // },
    // {
    //   name: "Pending",
    //   value: "2",
    //   is_cancel: "0",
    // },
    // {
    //   name: "Cancelled",
    //   value: "4",
    //   is_cancel: "1",
    // }
  ];

  const filterData = (item) => {
    if (item.value !== FilterButtonActive) {
      setFilterButtonActive(item.value);
      // apiCall(item.value, item.is_cancel)
    }
    // let filteredData = data.filter((item)=>item.status == )
    // setTableRows(data);
  };


  useEffect(() => {
    if (loading) {
      let type = "0";
      let is_cancel = "0";
      let params = {
        "type": type,
        "is_cancel": is_cancel
      }
      dispatch(bookingDataAPI(params));
    }
    if (!loading) {


      if (!loading) {
        let Arr = [];
        Arr = [
          {
            id: 'name',
            label: 'Name',
            sticky: false,
            align: 'left',
            minWidth: 150
          },
          {
            id: 'booking_number',
            label: 'Booking Number',
            sticky: false,
            align: 'center',
            minWidth: 150
          },
          {
            id: 'date_time',
            label: 'Booked On',
            sticky: false,
            align: 'center',
            minWidth: 150
          },
          // {
          //   id: 'created_at',
          //   label: 'Booked On',
          //   sticky: false,
          //   align: 'center',
          //   minWidth: 150
          // },
          {
            id: 'status',
            label: 'Status',
            sticky: false,
            align: 'center',
            minWidth: 150
          }
        ]
        setTableHead(Arr);
        setTableRows(data?.data);
      }

      //console.log("data.data >>> ", data?.data);
      //setReturnData(data.data);
    }
  }, [loading]);


  return (
    <>
      <div className="">
        <div className="grid grid-cols-1 w-full gap-4">
          <div>
            <div className="flex flex-col">
              <div className="overflow-x-auto">

                {/* <div>
                  <ButtonGroup aria-label="outlined button group">
                    {
                      filterButtons.map((item, i) => {
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <Button onClick={() => filterData(item)} variant={item.value === FilterButtonActive ? "contained" : "outlined"} className={item.value === FilterButtonActive ? "theme-btn" : ""} >{item.name}</Button>
                        )
                      })
                    }
                  </ButtonGroup>
                </div> */}

                <div className="p-1.5 w-full inline-block align-middle">
                  <hr className="my-3" />
                  <div className="overflow-hidden">
                    <MyBookingTable tableHead={TableHead} tableRows={TableRows} />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default UserBookingPage;
