import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../constants/axiosInstance";
import { getCrtToken } from "../../constants/randomFunctions";


export const pageDataAPI = createAsyncThunk(
    'pageData',
    async (params={}, thunkAPI) => {
      
      const response = await axiosInstance(getCrtToken).post(
        "/pages",
        params
      )
      return response.data
    }
  )

const PagesSlice = createSlice({
    name: "HomePage",
    initialState: {
        loading: true,
        data:[],
        error:null,
        message:null,
    },
    reducers:{
        getpageData: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        }
    },
    // extraReducers: {
    //     [pageDataAPI.fulfilled]:(state, action) => {
    //         state.loading = false;
    //         state.data = action.payload;
    //         // state.data = action.payload;
    //     }
    // }
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(pageDataAPI.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            // Add user to the state array
            // state.entities.push(action.payload)
        })
      },
})

export const {
    getpageData
} = PagesSlice.actions;

export default PagesSlice.reducer;