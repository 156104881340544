import UserDashboardPage from './UserDashboardPage';
import UserBookingPage from './UserBookingPage';
import UserProfilePage from './UserProfilePage';
import UserBookingDetailPage from './UserBookingDetailPage';


export default {
    UserDashboardPage,
    UserBookingDetailPage,
    UserProfilePage,
    UserBookingPage,
}