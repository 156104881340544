import { Typography } from '@mui/material'
import React from 'react'

const SectionHeading = (props) => {
    const {
        heading,
        subHeading
    } = props
    return (
        <>
            {
                heading && (
                    <Typography gutterBottom variant="h9" component="h2" color="primary" className='text-center text-4xl font-bold theme-text-color'>
                        {heading}
                    </Typography>
                )
            }
            {
                subHeading && (
                    <Typography gutterBottom variant="h9" component="h4" color="primary" sx={{
                        fontStyle:'italic',
                        fontWeight:'500'
                    }} className={'my-4 text-lg text-center theme-text-color'} >
                        {subHeading}
                    </Typography>
                )
            }
        </>
    )
}

export default SectionHeading