import { AiOutlineShoppingCart } from "react-icons/ai";
import { SlMenu } from "react-icons/sl";
import { AppBar, Avatar, Badge, Box, Button, Container, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography, useScrollTrigger } from '@mui/material';
import React, { Fragment } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { LOGO } from "../../images";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../CustomButton";
import Cookies from "js-cookie";
import { userLogoutAPI } from "../../redux/Slice/UserProfilePageSlice";

import PropTypes from 'prop-types';
import Slide from '@mui/material/Slide';



function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};




export const userLogout = (dispatch) => {
  let msg = window.confirm('are you sure want to logout!')
  if (msg) {
    Cookies.remove('token')
    dispatch(userLogoutAPI());
  }
  // console.log(12112123)
}

const HeaderComponent = (props) => {

  const { data: cartData, loading: cartLoading } = useSelector(
    (state) => state.CartPage
  );
  
  const { isAuth } = useSelector((state) => state.UserProfilePage);


  const pages = ['Products', 'Pricing', 'Blog'];
  const pages1 = [
    {
      label: "Home",
      link: "/",
      action: () => { }
    },
    {
      label: "All Packages",
      link: "/packages",
      action: () => { }
    },
    {
      label: "All Tests",
      link: "/tests",
      action: () => { }
    },
    {
      label: "About Us",
      link: "/about-us",
      action: () => { }
    },
    {
      label: "Our Labs",
      link: "/ourlabs",
      action: () => { }
    },
    {
      label: "Blogs",
      link: "/blogs",
      action: () => { }
    },
    {
      label: "Reports",
      link: (isAuth ? "/account/my-booking" : '/login'),
      action: () => { }
    },
    {
      label: "Contact Us",
      link: "/contact-us",
      action: () => { }
    },
    {
      label: "Make Payment",
      link: "/make-payment",
      action: () => { }
    },
  ];

  // const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
  const settings = [
    {
      label: "Dashboard",
      link: "/account",
      action: () => { }
    },
    {
      label: "My Profile",
      link: "/account/profile",
      action: () => { }
    },
    {
      label: "All Booking",
      link: "/account/my-booking",
      action: () => { }
    },
    {
      label: "Logout",
      link: "#0",
      action: () => { }
    },
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);


  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };


  return (
    <>
 <HideOnScroll {...props}>

      <AppBar sx={{
        backgroundColor: '#fff',
        position:'sticky',
        zIndex:'9'
      }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box
              component={Link}
              to="/"
              className="topLogo1" sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}>
              <img alt="LOGO" src={LOGO} />
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <SlMenu />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages1.map((page, p) => (
                  <>
                     {/* <MenuItem key={p} onClick={handleCloseNavMenu}> */}
                    <MenuItem key={p} onClick={handleCloseNavMenu}>
                      <Typography component={Link} to={page?.link} textAlign="center">{page?.label}</Typography>
                    </MenuItem>
                  </>
                ))}
              </Menu>
            </Box>
            {/* <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
            <AdbIcon  /> 
          </Box> */}
            <Box
              component={Link}
              to="/"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              <img alt="LOGO" src={LOGO} />
            </Box>
            {/* <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            LOGO
          </Typography> */}
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} className="main-menu">
              {pages1.map((page, p) => (
                <Fragment key={p}>
                  <Link to={page?.link}>{page?.label}</Link>
                  {/* <Button
                  key={p}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {page?.label}
                </Button> */}
                </Fragment>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              {
                isAuth ?
                  <>

                    <IconButton className="" sx={{
                      mr: 1
                    }} onClick={() => {
                      navigate('/cart')
                    }}>
                      <Badge badgeContent={cartData?.length} color="error">
                        <AiOutlineShoppingCart />
                      </Badge>
                    </IconButton>

                    <Tooltip title="Account">
                      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <Avatar alt="LLLLL Sharp" src="/static/images/avatar/2.jpg" />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      sx={{ mt: '45px' }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      {settings.map((setting, s) => {
                        if (s === (settings.length - 1)) {
                          return (
                            <MenuItem key={s} onClick={handleCloseUserMenu}>
                              <Typography onClick={() => userLogout(dispatch)} component={Link} to={setting?.link} textAlign="center">{setting?.label}</Typography>
                            </MenuItem>
                          )
                        } else {
                          return (
                            <MenuItem key={s} onClick={handleCloseUserMenu}>
                              <Typography component={Link} to={setting?.link} textAlign="center">{setting?.label}</Typography>
                            </MenuItem>
                          )
                        }

                      })}
                    </Menu>
                  </>
                  :
                  <>
                    <CustomButton onClick={() => {
                      navigate('/login')
                    }} >Login</CustomButton>
                  </>
              }
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
 </HideOnScroll>
      
    </>
  )
}

export default HeaderComponent
