import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../constants/axiosInstance";
import { getCrtToken } from "../../constants/randomFunctions";


export const userProfileDataAPI = createAsyncThunk(
  'userProfileData',
  async (params = {}, thunkAPI) => {
    console.log('data?.token >>> params >>>> ', params)
    const response = await axiosInstance((params.token || getCrtToken)).post(
      "/customer/profile-get",
      params
    )
    return response.data
  }
)

export const userProfileUpdateAPI = createAsyncThunk(
  'userProfileUpdate',
  async (params = {}, thunkAPI) => {
    const response = await axiosInstance(getCrtToken).post(
      "/customer/profile-update",
      params
    )
    return response.data
  }
)

export const userLogoutAPI = createAsyncThunk(
  'userLogout',
  async (params = {}, thunkAPI) => {
    const response = await axiosInstance(getCrtToken).post(
      "/customer/logout",
      params
    )
    return response.data
  }
)


const UserProfilePageSlice = createSlice({
  name: "UserProfilePage",
  initialState: {
    loading: true,
    isAuth: false,
    data: [],
    error: null,
    msgType: null,
    msg: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // UserProfile OR Register 
    builder.addCase(userProfileDataAPI.pending, (state, action) => {
      state.loading = true;
      state.msgType = null;
      state.error = false;
    });
    builder.addCase(userProfileDataAPI.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.isAuth = true;
      state.msgType = "success";
      state.msg = action.payload?.msg;
      state.data = action.payload;
    });
    builder.addCase(userProfileDataAPI.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.isAuth = false;
      state.msgType = "error";
      state.msg = action.payload?.msg;
      state.data = action.payload;
    });

    // User Profile Update API
    builder.addCase(userProfileUpdateAPI.pending, (state, action) => {
      state.loading = true;
      state.msgType = null;
      state.error = false;
    });
    builder.addCase(userProfileUpdateAPI.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.msgType = "success";
      state.msg = action.payload?.msg;
      state.data = action.payload;
    });
    builder.addCase(userProfileUpdateAPI.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.msgType = "error";
      state.msg = action.payload?.msg;
      state.data = action.payload;
    });

    // User Profile Update API
    builder.addCase(userLogoutAPI.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(userLogoutAPI.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.isAuth = false;
      state.msgType = "success";
      state.msg = action.payload?.msg;
      state.data = action.payload;
    });
    builder.addCase(userLogoutAPI.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.msgType = "error";
      state.msg = action.payload?.msg;
      state.data = action.payload;
    });


  },
})

export const {
  getUserProfileData
} = UserProfilePageSlice.actions;

export default UserProfilePageSlice.reducer;