import React, { useRef, useState } from 'react'
import { useEffect } from 'react';
import {
  Modal,
  Ripple,
  initTE,
} from "tw-elements";
import InputComponent from './form/InputComponent';
import axiosInstance from '../constants/axiosInstance';
import { useForm } from 'react-hook-form';
import AlertComponent from './AlertComponent';


const EnquiryModal = () => {
  
  const [MessageType, setMessageType] = useState("");
  const [Msg, setMsg] = useState("");
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    register,
  } = useForm({});

  const onSubmit = async (data) => {
    let n_data = {...data, type: 'enquiry'};
    // console.log('errors ????', errors)
    // console.log('n_data >>>> ', n_data)
    await axiosInstance()
      .post("/save-contact-us", n_data)
      .then((res) => {
        let resData = res?.data;
        // console.log(resData)
        if (resData.status) {
          reset()
          setMsg(resData?.msg);
          setMessageType(resData?.msgType);
          setTimeout(() => {
            divRef.current.click();
          }, 2000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const btnRef = useRef(null);
  const divRef = useRef(null);

  let crtUser  ={}

  useEffect(()=>{
    
    initTE({ Modal, Ripple });
    setTimeout(() => {
      if(btnRef.current){
        btnRef.current.click();
      }
    }, 2000);
  }, [])
  return (
    <>
     <div className="space-y-2">
  
  <button
    ref={btnRef}
    type="button"
    className="hidden rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] "
    data-te-toggle="modal"
    data-te-target="#exampleModalCenter"
    data-te-ripple-init
    data-te-ripple-color="light">
    Vertically centered modal
  </button>
</div>


<div
    ref={divRef}
  data-te-modal-init
  className="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
  id="exampleModalCenter"
  tabindex="-1"
  aria-labelledby="exampleModalCenterTitle"
  aria-modal="true"
  role="dialog">
  <div
    data-te-modal-dialog-ref
    className="pointer-events-none relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] items-center opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:min-h-[calc(100%-3.5rem)] min-[576px]:max-w-[500px]">
    <div
      className="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none ">
      <div
        className="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 ">
        
        <h5
          className="text-xl font-medium leading-normal text-neutral-800 "
          id="exampleModalCenterTitle">
          Enquiry
        </h5>
        
        <button
          type="button"
          className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
          data-te-modal-dismiss
          aria-label="Close">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="h-6 w-6">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
      
      <div className="relative p-4">
      {MessageType && (
              <>
                <AlertComponent msgType={MessageType} msg={Msg} />
              </>
            )}

      <div>
              <div className="form-group mb-3">
                <InputComponent
                  label="Name"
                  name="name"
                  type="text"
                  placeholder="Enter Full Name"
                  errors={errors?.name}
                  control={control}
                  aria-invalid={"true"}
                  rules={{ required: "This Field is required!" }}
                />
              </div>
              <div className="form-group mb-3">
                <InputComponent
                  label="Mobile Number"
                  name="mobile"
                  type="text"
                  value={crtUser?.mobile}
                  errors={errors?.mobile}
                  placeholder="Enter Mobile Number"
                  // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                  control={control}
                  rules={{ required: true, maxLength: 10, minLength: 10 }}
                />
              </div>
              
              <div className="form-group mb-3">
                <label className="block mb-2 text-sm font-medium text-gray-900">
                  Message
                </label>

                <textarea
                  {...register("message")}
                  className=" form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  id="exampleFormControlTextarea13"
                  rows="3"
                  placeholder="Message"
                ></textarea>
              </div>
              </div>
      </div>

      
      <div
        className="flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 ">
        <button
          type="button"
          className="inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
          data-te-modal-dismiss
          data-te-ripple-init
          data-te-ripple-color="light">
          Close
        </button>
        <button
          type="submit"
          className="ml-1 inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
          data-te-ripple-init
          data-te-ripple-color="light">
          Submit
        </button>
      </div>

    </form>



    </div>
  </div>
</div>

    </>
  )
}

export default EnquiryModal
