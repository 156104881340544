import { Box, Container } from '@mui/material'
import React from 'react'
import SectionHeading from './SectionHeading'

const SectionComponent = (props) => {
    const { 
        heading,
        subHeading,
        className,
        children
    } = props
  return (
    <Box component={'section'} className={className} sx={{
        paddingY:5
    }}>
        
      <Container maxWidth="xl">
        <Box sx={{
            textAlign:"center"
        }}>
            <SectionHeading 
                heading={heading}
                subHeading={subHeading}
            />
        </Box>
        <Box>
            {children}
        </Box>
      </Container>
    </Box>
  )
}

export default SectionComponent