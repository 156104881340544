import React, { useEffect } from 'react'
import AddToCartButton from './AddToCartButton';
import { Link } from 'react-router-dom';
import CustomButton from './CustomButton';

const PackageCard = (props) => {
  const { itemData } = props;
  useEffect(() => {
    console.log('itemData <>>>> ', itemData)
  }, [])

  return (
    <>
      <div className="rounded overflow-hidden shadow-lg custom-card-1 p-5 hover:bg-gray-50">
        <div className="card-content">
          <h3 className="text-lg">
            <a className="no-underline hover:underline text-black" href="#">
              {itemData?.name ? itemData?.name : "Article Title"}
            </a>
          </h3>
          <div className="w-full text-gray-900 ">

            {
              itemData?.short_detail?.d1 ? (
                <>
                  <div className="inline-flex relative items-center py-2 w-full text-sm font-medium rounded-t-lg ">
                    <svg aria-hidden="true" className="mr-2 w-4 h-4 fill-current" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd"></path></svg>
                    {itemData?.short_detail?.d1}
                  </div>
                </>
              ) :
                <>
                </>
            }
            {
              itemData?.test_count ? (
                <div className="inline-flex relative items-center py-2 w-full text-sm font-medium ">
                  <svg aria-hidden="true" className="mr-2 w-4 h-4 fill-current" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z"></path></svg>
                  {itemData?.test_count} Tests
                </div>
              ) :
                <>
                </>
            }
            {
              itemData?.short_detail?.sample_report ? (
                <div className="inline-flex relative items-center py-2 w-full text-sm font-medium rounded-b-lg ">
                  <a className="inline-flex " href={itemData?.short_detail?.sample_report} target='_blank'>
                    <svg aria-hidden="true" className="mr-2 w-4 h-4 fill-current" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M2 9.5A3.5 3.5 0 005.5 13H9v2.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 15.586V13h2.5a4.5 4.5 0 10-.616-8.958 4.002 4.002 0 10-7.753 1.977A3.5 3.5 0 002 9.5zm9 3.5H9V8a1 1 0 012 0v5z" clipRule="evenodd"></path></svg>
                    Download Sample Report
                  </a>
                </div>
              )
                :
                <>
                </>
            }
          </div>
        </div>
        <div>
          <div className="card-price">
            <h6 className="text-2xl font-bold">
              ₹ {itemData?.price || 0}
            </h6>
          </div>
          <div className="card-footer unset sm:flex justify-between">
            <Link to={`/packages/${itemData?.slug}`}>
              {/* <button className="border-2 border-sky-500  my-2 sm:mr-4 hover:bg-sky-500 hover:text-white text-sky-500 font-bold py-2 rounded-full w-full">View Detail</button> */}
              <CustomButton variant={"outlined"} >View Detail</CustomButton>
            </Link>
            {/* <button className="bg-sky-500 my-2 sm:ml-4 hover:bg-sky-500 text-white font-bold py-2 rounded-full w-full">Book Now</button> */}
            <AddToCartButton itemId={itemData?.id} type="package" />
          </div>
        </div>
      </div>
    </>
  )
}

export default PackageCard