import React, { useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { dashboardDataAPI } from "../../redux/Slice/DashboardPageSlice";
import { Box, Button, Card, CardActions, CardContent, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const UserDashboardPage = () => {
  let cardArr = [];

  const { data, loading } = useSelector((state) => state.DashboardPage);

  const dispatch = useDispatch();

  const [returnData, setReturnData] = useState(null);

  useEffect(() => {
    if (loading) {
      dispatch(dashboardDataAPI());
    }
    if (!loading) {
      console.log("data.data >>> ", data?.data);
      setReturnData(data.data);
    }
  }, [loading]);


  const navigate = useNavigate();


  const card = (item) => (
    <React.Fragment>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {item.title}
        </Typography>
        <Typography variant="h5" component="div">
          {item.count}
        </Typography>

      </CardContent>
      <CardActions>
        <CustomButton variant="outlined" onClick={() => {
          if (item?.link) {
            navigate(item?.link)
          }
        }} size="small">View</CustomButton>
      </CardActions>
    </React.Fragment>
  );

  const ButtonArrow = (title, link) => {
    return (
      <a href={link}>
        <CustomButton variant={"outlined"}>
          {title}
          <svg
            className="ml-2 -mr-1 h-4 w-4"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </CustomButton>
      </a>
    );
  };

  return (
    <>
      <div className="">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 w-full gap-4">
          {returnData &&
            returnData.length > 0 &&
            returnData.map((item, index) => {
              if (index == 0) {

                return (
                  <>
                    <Card variant="outlined">{card(item)}</Card>
                    {/* <Card key={index} className="bg-transparent">
                      <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        {item?.count ? item?.count : 0}
                      </h5>
                      <p className="font-bold">{item.title}</p>
                      {ButtonArrow("View All", item?.link || "#0")}
                    </Card> */}
                  </>
                );
              }
            })}
        </div>
      </div>
    </>
  );
};

export default UserDashboardPage;
