import React, { useEffect, useState } from 'react'
import SubHeaderSection from '../components/SubHeaderSection'
import SectionComponent from '../components/SectionComponent'
import InputComponent from '../components/form/InputComponent'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { useForm } from 'react-hook-form'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { checkoutDataAPI } from '../redux/Slice/CheckoutPageSlice'
import { cartDataAPI } from '../redux/Slice/CartPageSlice'
import { getCartListParams, getCrtToken } from '../constants/randomFunctions'
import axios from 'axios'
import axiosInstance, { api_base_url } from '../constants/axiosInstance'

const Cart_CheckOutPage = () => {

    let key = "rzp_test_N3hqgRWX2ZU8Ts";

    const [value, setValue] = React.useState('2');

    const [crtUser, setCrtUser] = useState({});


    const { data: profileData, loading: profileLoading, isAuth } = useSelector((state) => state.UserProfilePage);
    const new_a0 = useSelector((state) => state.UserProfilePage);

    const {
        data,
        msg,
        error,
        loading,
        msgType,
    } = useSelector(
        (state) => state.CheckoutPage
    );

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    // let crtUser = {}

    const dispatch = useDispatch();

    const { register, control, formState: { errors }, handleSubmit, reset } = useForm({
        defaultValues: {
            // full_name: crtUser?.name,
            // email: crtUser?.email,
            // mobile: crtUser?.mobile,
            // mobile_2: crtUser?.mobile2,
            // address: crtUser?.address,
            // landmark: crtUser?.landmark,
            // locality: crtUser?.locality,
            // pin_code: crtUser?.pin,
            // state: crtUser?.state,
            // city: crtUser?.city,
            payment_type: value
        }
    });



    const handlePayment = async (transaction)  => {
        // await axiosInstance.get(api_base_url+'/customer/cart/checkout/payData')
        await axiosInstance(getCrtToken).post(
            "/customer/cart/checkout/payData",
            {
                transaction_id: transaction.id,
                amount: transaction?.booking_data?.total_amount,
            }
          )
        .then(res=>{
            // setPayDataRes(res.data)
            // setOrderId(res.data.order_id);
            step2(res.data)
            // console.log('APT res >>>> ', res)
        }).catch(err=>{
            console.log('err >>> ', err)
        })
    }
    const step2 = (order) => {
        const options = {
            key: key,
            name: "DR Path Care",
            currency: 'INR',
            amount: order.amount,
            order_id: order.order_id,
            description: "Understanding RazorPay Integration",
            // image: logoBase64,
            handler: async function (response) {
              console.log('response >>>> ', response);
              headers:
              await axiosInstance(getCrtToken).post(api_base_url+"/customer/cart/checkout/verifyPayDataAPI", {
                last_transaction_id: data?.id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_order_id: response.razorpay_order_id,
                razorpay_signature: response.razorpay_signature,
              }).then((t) => {
                    window.scroll(0, 0);
                    navigate('/cart/thank-you');

              }).catch(e0=>console.log(' e0 >>>> ', e0));
    
            },
            prefill: {
              name: profileData?.data.name || "",
              email: profileData?.data?.email || "",
              contact: profileData?.data?.mobile || "",
            },
          };
      
          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
      
          paymentObject.on("payment.failed", function (response) {
            alert("Payment failed. Please try again. Contact support for help");
          });
    }



    const onSubmit = async (data) => {
        
        let nData = data;

        nData = { ...nData, payment_type: value }
        console.log('nData >>> ', nData)
        // return
        dispatch(checkoutDataAPI(nData));
        dispatch(cartDataAPI(getCartListParams));

        if(nData.payment_type == "2"){
            setTimeout(() => {
                // navigate('/cart/thank-you')
                // handlePayment();
            }, 2000);
        }else{
            setTimeout(() => {
                navigate('/cart/thank-you')
            }, 2000);
        }


        // try {
        //   userCartCheckOutRequest(data)
        //   .then(res=>{
        //     console.log(res?.data);
        //     if(value == 2){
        //     //   makePayment(data, res?.data);
        //       return ;
        //     }else{
        //     //   router.push('/cart/thank-you')
        //     }

        //   }).catch(err=>{
        //     console.log(err)
        //   })

        // } catch (error) {
        //   console.log(error)
        // }

    };
    const validateMobileNumber = (value) => {
        // A simple regex to validate a 10-digit mobile number
        const mobileRegex = /^[0-9]{10}$/;
    
        return mobileRegex.test(value) || 'Invalid mobile number';
      };
    const validateEmail = (value) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value) || 'Invalid email address';
    };
    


    useEffect(() => {
        // console.log('a0000 >>> ', profileLoading)
        if (!profileLoading) {
            let a00 = profileData?.data;
            setCrtUser(profileData?.data);

            let a0 = {
                full_name: a00?.name,
                email: a00?.email,
                mobile: a00?.mobile,
                mobile_2: a00?.mobile2,
                address: a00?.address,
                landmark: a00?.landmark,
                locality: a00?.locality,
                pin_code: a00?.pin,
                state: a00?.state,
                city: a00?.city,
                payment_type: value
            }
            reset(a0)
        }
    }, [profileLoading]);
    useEffect(() => {

    }, [crtUser]);

    const navigate = useNavigate();

    useEffect(() => {
        if (!loading) {
            if (msgType === "success" && value == "2") {
                // window.scroll(0, 0)
                // navigate('/cart/thank-you')
                handlePayment(data)
            }else
            if (msgType === "success" && value == "1") {
                window.scroll(0, 0)
                navigate('/cart/thank-you')
            }
            // if (msgType === "success") {
            //     window.scroll(0, 0)
            //     navigate('/cart/thank-you')
            // }
        }
    }, [msgType, loading])





    return (
        <>

            <SubHeaderSection title={"Checkout"} />
            <SectionComponent>

                <div className="cart-container checkout-container">

                    <div className='cartDerail'></div>
                    <div className='userInfo'>
                        <div className="w-full">
                            <form onSubmit={handleSubmit(onSubmit)} >
                                <div className="grid gap-6 mb-6 md:grid-cols-2">
                                    <div>
                                        <InputComponent
                                            label="Full Name"
                                            name="full_name"
                                            type="text"
                                            placeholder="John"
                                            errors={errors?.full_name}
                                            value={crtUser?.name}
                                            control={control}
                                            aria-invalid={"true"}
                                            rules={{ required: "This Field is required!" }}
                                        />
                                    </div>


                                    <div>
                                        <InputComponent
                                            label="Email Address"
                                            type="email"
                                            name="email"
                                            value={crtUser?.email}
                                            // readOnly="readonly"
                                            id="email"
                                            placeholder="john.doe@company.com"
                                            control={control}
                                            errors={errors?.email}
                                            // rules={{ required: true }}
                                            rules={{ validate: validateEmail }}
                                        />
                                    </div>

                                    <div>

                                        <InputComponent
                                            label="Mobile number"
                                            name="mobile"
                                            type="text"
                                            // readOnly="readonly"
                                            value={crtUser?.mobile}
                                            placeholder="123-45-678"
                                            // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                            control={control}
                                            errors={errors?.mobile}
                                            // rules={{ required: true }}
                                            rules={{ validate: validateMobileNumber }}
                                        />

                                    </div>

                                    <div>

                                        <InputComponent
                                            label="Alter Native/ WhatsApp Number"
                                            name="mobile_2"
                                            value={crtUser?.mobile2}
                                            type="text"
                                            placeholder="123-45-678"
                                            control={control}
                                        />
                                    </div>


                                    <div>


                                        <InputComponent
                                            label="Address"
                                            name="address"
                                            value={crtUser?.address}
                                            type="text"
                                            placeholder="Address"
                                            control={control}
                                        />


                                    </div>
                                    <div>

                                        <InputComponent
                                            label="Landmark"
                                            name="landmark"
                                            value={crtUser?.landmark}
                                            type="text"
                                            placeholder="Landmark"
                                            control={control}
                                        />


                                    </div>
                                    <div>

                                        <InputComponent
                                            label="Locality"
                                            name="locality"
                                            value={crtUser?.locality}
                                            type="text"
                                            placeholder="Locality"
                                            control={control}
                                        />

                                    </div>
                                    <div>
                                        <InputComponent
                                            label="Pin Code"
                                            name="pin"
                                            value={crtUser?.pin}
                                            type="text"
                                            placeholder="Pin Code"
                                            control={control}
                                        />
                                    </div>

                                    <div>

                                        <InputComponent
                                            label="State"
                                            name="state"
                                            value={crtUser?.state}
                                            type="text"
                                            placeholder="State"
                                            control={control}
                                        />
                                    </div>
                                    <div>
                                        <InputComponent
                                            label="City"
                                            name="city"
                                            value={crtUser?.city}
                                            type="text"
                                            placeholder="City"
                                            control={control}
                                        />
                                    </div>
                                    <div>
                                        <InputComponent
                                            label="Date"
                                            name="date"
                                            value={""}
                                            type="date"
                                            min={moment().add(1, "day").format('YYYY-MM-DD')}
                                            rules={{ required: true }}
                                            errors={errors?.date}
                                            placeholder="Appointment Date"
                                            control={control}
                                        />
                                    </div>
                                    <div>
                                        <InputComponent
                                            label="Time"
                                            name="time"
                                            value={""}
                                            type="time"
                                            errors={errors?.time}
                                            rules={{ required: true }}
                                            placeholder="Appointment Time"
                                            control={control}
                                        />
                                    </div>
                                    <div>
                                        <div>
                                            <FormControl>
                                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" >Pyment Method </label>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-form-control-label-placement"
                                                    name="payment_type"
                                                    defaultValue={"2"}
                                                    // rules={{ required: true }}
                                                    // onChange={handleChange}
                                                    {...register('payment_type')}
                                                >
                                                    <FormControlLabel value="1" control={<Radio onChange={handleChange} />} label="Pay Cash On Collection" />
                                                    <FormControlLabel value="2" control={<Radio onChange={handleChange} />} label="Pay Now" />
                                                </RadioGroup>
                                            </FormControl>

                                            {/* <FormControl>
                                                <label className="block mb-2 text-sm font-medium text-gray-900" >Payment Method </label>
                                                <RadioGroup
                                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                                    name="payment_type"
                                                    value={value}
                                                    onChange={handleChange}
                                                    rules={{ required: true }}
                                                // {...register('payment_type', { required: true })}
                                                >
                                                    <FormControlLabel value="1" control={<Radio />} label="Pay Cash On Collection" />
                                                </RadioGroup>
                                            </FormControl> */}

                                            {/* {
      errors && errors?.payment_type !== '' ?
      <>{errors?.payment_type}</>
      :
      <>
        <span role="alert">This is required</span>
      </>
    } */}
                                        </div>
                                    </div>
                                </div>

                                {/* <div>
  <Alert severity="info">pay cash on collection</Alert>
  </div> */}
                                <button
                                    type="submit"
                                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                >
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>

                </div>

            </SectionComponent>

        </>
    )
}

export default Cart_CheckOutPage
