import axios from "axios";
import Cookies from "js-cookie";
import { useContext } from "react";
import { MenuContext } from "../context/MenuContextProvider";

export const base_url = "https://www.drpathcare.com/";
// export const api_base_url = "https://vasudeep.com:8084/https://mrkaiweb.com/drpath_admin/public/api";
// export const api_base_url = "http://127.0.0.1:8000/api";
export const api_base_url = "https://drpathcare.com/crm/public/api";


const axiosInstance  =(token='')=> {
  // console.log('ALst Stage token >', token)
  return axios.create({
      baseURL: api_base_url,
      headers: {
        "Authorization": `Bearer ${token}`,
      }
    })
};


// const axiosInstance = axios.create({
//   baseURL: api_base_url,
// });

// // const {UserToken, setUserToken} = useContext(MenuContext)

// let token = Cookies.get("token");
// // token = "42|4t32FdIvog9ywI5xTycxTccej89pmBtIreXuy0TP";
// // console.log("UserToken >>>> ", UserToken);
// if (typeof window !== "undefined") {
//   if(token){
//     axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
//   }
//   // token = localStorage.getItem('token');
// }

export default axiosInstance;
