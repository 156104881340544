import React, { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import moment from 'moment/moment';
import { Button, Chip } from '@mui/material';
import CustomButton from '../../components/CustomButton';
import { useNavigate } from 'react-router-dom';




const MyBookingTable = (props) => {

    const { tableHead, tableRows } = props;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const navigate = useNavigate();
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        // console.log(props.tableRows.length)
    }, [props])


    return (
        <>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: '100%' }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {tableHead && tableHead.map((column) => (
                                    <TableCell
                                        key={column?.id}
                                        align={column?.align}
                                        style={{ minWidth: column?.minWidth, backgroundColor: '#c8edff' }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                                <TableCell
                                    key={'action'}
                                    align="right"
                                    style={{ backgroundColor: '#c8edff' }}
                                >
                                    Action
                                </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {tableRows && tableRows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    console.log(row)
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                            {
                                                tableHead && tableHead.map((column) => {
                                                    const column_id = column.id;
                                                    let value = '';
                                                    if (column_id === 'name') {
                                                        let aaa = row?.booking_patient.map((item) => item?.name);
                                                        value = aaa.join(', ');
                                                    } else
                                                        if (['date_time' , 'created_at'].includes(column_id)) {
                                                            value = moment(row.created_at).format('dddd, DD-MMM-YYYY');
                                                        } else {
                                                            value = row[column.id];
                                                        }
                                                    if (column_id === 'status') {

                                                        let status_color = '';
                                                        if (row?.status === "0") {
                                                            status_color = 'warning';
                                                        } else
                                                            if (row?.status === "1") {
                                                                status_color = 'info';
                                                            } else
                                                                if (row?.status === "2") {
                                                                    status_color = 'secondary';
                                                                } else
                                                                    if (row?.status === "3") {
                                                                        status_color = 'success';
                                                                    }

                                                        return (
                                                            <TableCell key={column.id} align={column.align}>
                                                                <Chip size="small" label={row?.status_label} color={status_color} sx={{}} />
                                                            </TableCell>
                                                        );
                                                    } else {
                                                        return (
                                                            <TableCell key={column.id} align={column.align}>
                                                                {
                                                                    column.format && typeof value === 'number'
                                                                        ? column.format(value)
                                                                        : value
                                                                }
                                                            </TableCell>
                                                        );
                                                    }

                                                })}

                                            <TableCell
                                                key={'action'}
                                                align="right"
                                            >
                                                <CustomButton onClick={(e) => navigate(`/account/my-booking/detail/${row?.id}`)} size="small" variant='contained' color="primary" className='theme-btn'>
                                                    View Detail
                                                </CustomButton>

                                                {
                                                    row?.booking_patient && row?.booking_patient[0]?.report_file && (
                                                        <>
                                                            <a href='' target='blank'>
                                                                <CustomButton size="small" variant='contained' color="primary" className='theme-btn'>
                                                                    Download Report
                                                                </CustomButton>
                                                            </a>
                                                        </>
                                                    )
                                                }

                                                {
                                                    row?.booking_patient && row?.booking_patient[0]?.report_file && (
                                                        <>
                                                            <a href='' target='blank'>
                                                                <CustomButton size="small" variant='contained' color="primary" className='theme-btn'>
                                                                    Download Diet Chart
                                                                </CustomButton>
                                                            </a>
                                                        </>
                                                    )
                                                }

                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25]}
                    component="div"
                    count={tableRows && tableRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>

        </>
    )
}

export default MyBookingTable