import React, { useEffect, useState } from 'react'
import SubHeaderSection from '../components/SubHeaderSection'
import { useDispatch, useSelector } from 'react-redux';
import SectionComponent from '../components/SectionComponent';
import { CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { blogDataAPI } from '../redux/Slice/BlogPageSlice';

const BlogsPage = () => {

    const {
        data, loading
      } = useSelector((state) => state.BlogPageRed)
    
    const dispatch = useDispatch();

    const [returnData, setReturnData] = useState(null)

    useEffect(()=>{
        if(loading){
            dispatch(blogDataAPI());
        } 
        if(!loading){
            setReturnData(data.data);
        }
      }, [dispatch, loading])

    return (
        <>
            <SubHeaderSection title={"Blogs"} />
            <SectionComponent >
            <div className="-mx-4 flex flex-wrap">
                {
                    returnData && returnData.map((item, i)=>{
                        let b_link = `/blogs/${item?.slug}`;
                        return (
                        <React.Fragment key={i}>
                            <div className="w-full px-4 md:w-1/2 lg:w-1/3">
                                <Link to={b_link}>
                                <div className="mx-auto mb-10 max-w-[370px]">
                                    <div className="mb-2 overflow-hidden rounded">
                                    <img 
                                        alt={item.name}
                                        src={item.imageUrl}
                                        style={{
                                            width: '100%',
                                            height: '200px'
                                        }}
                                    />
                                    {/* <ImageLoader
                                        srcLink={removeImageName(item.imageUrl, item?.image)}
                                        src={item.image}
                                        alt="Picture of the author"
                                        width={1024}
                                        height={500}
                                        /> */}
                                    </div>
                                    <div>
                                        {
                                            item?.date && (
                                            <span
                                            style={{
                                                backgroundColor:"#7dd3fc"
                                            }}
                                                className=" mb-1 inline-block rounded py-1 px-4 text-center text-xs font-bold leading-loose text-white"
                                            >
                                                {item?.date}
                                            </span>

                                            )
                                        }
                                    <h3>
                                        <Link
                                            to={b_link}
                                            className="text-dark hover:text-primary mb-4 inline-block text-xl font-semibold sm:text-2xl lg:text-xl xl:text-2xl"
                                        >
                                            {item?.name}
                                        </Link>
                                    </h3>
                                    {/* <p className="text-body-color text-base">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting
                                        industry.
                                    </p> */}
                                    </div>
                                </div>
                                </Link>
                            </div>
                    
                        </React.Fragment>
                        )
                    })
                }
            </div>
            </SectionComponent>
        </>
    )
}

export default BlogsPage