import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'
import { bookingDataAPI } from '../../redux/Slice/BookingPageSlice';
import { getGender } from '../../constants/randomFunctions';
import CustomButton from '../../components/CustomButton';

const UserBookingDetailPage = () => {

    const { id } = useParams();
    const dispatch = useDispatch();
    const { data, loading } = useSelector((state) => state.BookingPage);
    const [ActiveData, setActiveData] = useState({});

   useEffect(() => {
    
    if (loading) {
        let type = "0";
        let is_cancel = "0";
        let params = {
          "type": type,
          "is_cancel": is_cancel
        }
        dispatch(bookingDataAPI(params));
      }
      if (!loading) {
        let crtData = data.data.find(item=>item.id == id);
        console.log('crtData >>> ', crtData)
        setActiveData(crtData);
      }

   }, [loading])
   



    return (
        <>
            <div className='b-detail'>
                <div  className='flex gap-1 justify-between items-center mb-5'>
                    <h4>Booking No: #{ActiveData?.booking_number}</h4>
                    {/* <div className='flex gap-2 justify-between items-center' style={{}}>
                        <CustomButton variant={"contained"} >Report</CustomButton>
                        <CustomButton variant={"contained"} >Diet Chart</CustomButton>
                    </div> */}
                </div>
                <div className='table-custom'>
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>package</th>
                                <th>Test</th>
                                <th>Gender</th>
                                <th>Age</th>
                                <th>Price</th>
                                <th>Report</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                ActiveData.booking_patient && ActiveData.booking_patient.length > 0 && ActiveData.booking_patient.map((item, i)=>{
                                    let itemTest = item.testArr && item.testArr.length > 0 && item.testArr.map(tItm=>tItm?.name);
                                    let itemPackageArr = item.packageArr && item.packageArr.length > 0 && item.packageArr.map(tItm=>tItm?.name);
                                    return (
                                        <>
                                        <tr>
                                            <td>{item?.name}</td>
                                            <td>{itemPackageArr.join(', ')}</td>
                                            <td>{itemTest && itemTest.join(', ')}</td>
                                            <td>{getGender(item?.gender)}</td>
                                            <td>{item?.age}</td>
                                            <th>{item?.price ? `₹ ${item?.price}` : ""}</th>
                                            <th>
                                                <div className='flex gap-2' style={{}}>
                                                    {
                                                        item?.report_file && (
                                                            <>
                                                                <CustomButton variant={"contained"} >Report</CustomButton>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        item?.diet_chart_file && (
                                                            <>
                                                                <CustomButton variant={"contained"} >Diet Chart</CustomButton>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </th>
                                        </tr>

                                        </>
                                    )
                                })
                            }
                            
                        </tbody>
                        <tfoot style={{
                            backgroundColor: '#ccc',
                        }}>
                            <tr>
                                <th colSpan={6}>Total Amount</th>
                                <th>{ActiveData?.total_amount ? `₹ ${ActiveData?.total_amount}` : ""}</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div className='more-detail'>
                    <h5>More Detail</h5>
                    <div>
                        <p><b>Mobile: </b> {ActiveData?.mobile}</p>
                        <hr />
                        <p><b>Mobile2: </b> {ActiveData?.mobile2}</p>
                        <hr />
                        <p><b>Email Address: </b> {ActiveData?.email}</p>
                        <hr />
                        <p><b>Address: </b> {ActiveData?.address}</p>
                        <hr />
                        <p><b>PinCode: </b> {ActiveData?.pin}</p>
                        <hr />
                        <p><b>City: </b> {ActiveData?.city}</p>
                        <hr />
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserBookingDetailPage
