import Cookies from 'js-cookie';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = (props) => {
  const {
    children,
  } = props;
  const { data, loading, isAuth } = useSelector((state) => state.UserProfilePage);
  const navigate = useNavigate()
  useEffect(() => {
    if (isAuth === false && Cookies.get('token') == "") {
      navigate('/login');
    }
  }, [isAuth])



  // useEffect(() => {
  //     let a0 = ['omkaradata.in','omkaradata.com'];
  //     // let a0 = ['vdr.omkaracapital.in'];
  //     // console.log(window.location.host);
  //     if(!a0.includes(window.location.host)){
  //         if(!loggedInAdmin?.UserID){
  //             localStorage.clear();
  //             window.location.href = './login';
  //         }
  //     }
  // }, [])

  return children;
}

export default ProtectedRoute