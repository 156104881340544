import React from 'react'

const MakePayment = () => {
  return (
    <>
    <div className="container mx-auto pt-8">

      <div className='grid grid-cols-2 gap-8'>
        <div className='flex justify-center items-center flex-col'>
            <p className='text-2xl font-semibold'>Dr. Pathcare</p>
            <img src="/qr_code.jpg" alt="" className='w-full max-w-lg' />
            <p className='text-xl'>UPI - drpathcare1@axl</p>
        </div>
        <div className='flex justify-center items-start flex-col'>
            <p className='text-xl font-semibold'>Click here to make payment by Credit Card ur Net Banking:-</p>
            <p className='text-xl font-semibold'>https://razorpay.me/@drpathcare</p>
            <p className='text-xl font-semibold'>And</p>
        </div>
      </div>
    </div>
    </>
  )
}

export default MakePayment
