import React, { useEffect, useState } from 'react'
import SubHeaderSection from '../components/SubHeaderSection'
import { useDispatch, useSelector } from 'react-redux';
import { packageDataAPI } from '../redux/Slice/PackagePageSlice';
import SectionComponent from '../components/SectionComponent';
import PackageCard from '../components/PackageCard';
import { CircularProgress } from '@mui/material';

const OurLabsPage = () => {

    let imageArr = [
        {
            imageUrl: "https://mrkaiweb.com/drpath_admin/public/uploads/neuberg.png",
            image: "neuberg.png",
            title: "Neuberg Diagnostics",
        },
        {
            imageUrl: "https://mrkaiweb.com/drpath_admin/public/uploads/vedanta.png",
            image: "vedanta.png",
            title: "Vedanta Labs",
        },
        {
            imageUrl: "https://mrkaiweb.com/drpath_admin/public/uploads/niramaya.png",
            image: "niramaya.png",
            title: "Niramaya Health Care",
        },
        {
            imageUrl: "https://mrkaiweb.com/drpath_admin/public/uploads/Biolinelab.png",
            image: "Biolinelab.png",
            title: "Bioline Laboratory",
        }
    ]

    return (
        <>
            <SubHeaderSection title={"Our Labs"} />
            <SectionComponent >
                <div className="p-3 sm:p-5 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-5 ">
                    <div className="text-center ourlabs-img" style={{ position: "relative" }}>
                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2">
                            {
                                imageArr.map((item, i) => {
                                    return (
                                        <div key={i}>
                                            <img
                                                alt={item?.title}
                                                src={item.imageUrl}
                                            />
                                            {/* <ImageLoader
                                                srcLink={removeImageName(item.imageUrl, item.image)}
                                                src={item?.image}
                                                alt="About Us"
                                                sizes="100%"
                                                width={500}
                                                height={250}
                                            /> */}
                                            <h5>
                                                <b>{item?.title}</b>
                                            </h5>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="text-left items-center ourlabs-text">
                        <p>Dr pathcare India's is preventive health care company & offering a wide range of health services across 20+ cities of India, and counting.dr pathcare focuses on Quality that offers a rapid, reliable and accurate services to its customers.</p>
                        <ul className=''>
                            <li>It has a network of fully automated laboratories and large team of highly skilled phlebotomists who specialize in sample collection from home, we remain committed to delivering the highest quality services with all COVID 19 precautions to you at the convenience to your home.</li>
                            <li>At Dr pathcare, quality is a way of functional. Our stringent quality control parameters coupled with intelligent IT enabled systems ensure the consistent reproducibility of results and reports.</li>
                            <li>Our internal quality parameters include not only the systems and Instruments but also well trained and responsible staff to execute the entire processing in highly calibrated, sophisticated analysers.</li>
                        </ul>
                    </div>
                </div>
            </SectionComponent>
        </>
    )
}

export default OurLabsPage