import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { Controller } from 'react-hook-form'

const InputComponent = ({ name, control, label, rules, value, readonly, defaultValue, errors, ...props }) => {

  // React.useEffect(() => {
  //   console.log("rules:", value); 
  // }, [])
  useEffect(()=> {
    // console.log('value >>> ', value)
  },[])


  return (
    <>
      <Box sx={{ 
        position:'relative'
       }}>
        {/* dark:text-gray-300 */}
      <label className="block mb-2 text-sm font-medium text-gray-900" >{label} {rules?.required && (
        <span className='text-red-500 font-bold'>*</span>
      )}</label>

      <Controller
        rules={rules}
        name={name}
        control={control}
        render={({ field }) => <input 
          defaultValue={value}
          readOnly={readonly}
          className="
              border
              border-gray-300
              text-gray-900
              text-sm
              rounded-lg
              focus:ring-blue-500
              focus:border-blue-500
              block
              w-full
              p-2.5
            "
          {...field} {...props}
        />}
      />


      {/* dark:bg-gray-700
              dark:border-gray-600
              dark:placeholder-gray-400
              dark:text-white
              dark:focus:ring-blue-500
              dark:focus:border-blue-500 */}
      {
        errors &&
        (
          <div style={{ 
            position:'absolute',
            top: '100%'
           }}>
            {
              errors.message !== '' ?
                <><span role="alert" className='text-red-500 text-xs font-bold' >{errors.message}</span></>
                :
                <>
                  <span role="alert" className='text-red-500 text-xs font-bold' >This field is required</span>
                </>
            }
          </div>
        )
      }
      </Box>

    </>
  )
}

export default InputComponent