import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../constants/axiosInstance";
import { getCrtToken } from "../../constants/randomFunctions";


export const blogDataAPI = createAsyncThunk(
    'blogData',
    async (params={}, thunkAPI) => {
      const response = await axiosInstance(getCrtToken).post(
        "/blogs",
        params
      )
      return response?.data
    }
  )

const BlogPageSlice = createSlice({
    name: "BlogPage",
    initialState: {
        loading: true,
        data:[],
        error:null,
        message:null,
    },
    reducers:{
        getBlogData: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(blogDataAPI.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        })
      },
})

export const {
    getBlogData
} = BlogPageSlice.actions;

export default BlogPageSlice.reducer;