import React, { useEffect, useState } from 'react'
import SubHeaderSection from '../components/SubHeaderSection'
import { useDispatch, useSelector } from 'react-redux';
import { packageDataAPI } from '../redux/Slice/PackagePageSlice';
import SectionComponent from '../components/SectionComponent';
import PackageCard from '../components/PackageCard';
import { CircularProgress, IconButton, Typography } from '@mui/material';
import { FiX } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { cartDataAPI } from '../redux/Slice/CartPageSlice';

const Cart_Page = () => {

    const {
        data,
        cartDetail,
        loading,
        msg,
        error,
        msgType,
    } = useSelector(
        (state) => state.CartPage
    );

    const dispatch = useDispatch();

    const [returnData, setReturnData] = useState(null)

    const clickOnButton = (actionType, itemId, type) => {
        let params = {
            "source":"web",
            "type":actionType,
            "for":type,
            "test_id": itemId
        }
        dispatch(cartDataAPI(params))
    }

    useEffect(() => {
        if (!loading) {
            // setReturnData(data.data);
        }
    }, [loading])



    return (
        <>
            <SubHeaderSection title={"My Cart"} />
            <SectionComponent >
                        <div className="cart-container">

                            {
                                data && data.length !== 0
                                    ?
                                    <>
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th style={{ maxWidth: '30px' }}></th>
                                                    <th>Title</th>
                                                    <th style={{ width: '100px' }}>Price</th>
                                                    {/* <th style={{width:'100px'}}>Tests</th> */}
                                                    <th style={{ width: '30px' }}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data.map((item, i) => {
                                                        const itemDetail = item.itemDetail;
                                                        return (
                                                            <tr key={i}>
                                                                <td>{i + 1}.</td>
                                                                <td>{itemDetail?.name}</td>
                                                                <td>₹ {itemDetail?.price}</td>
                                                                {/* <td>5+</td> */}
                                                                <td>
                                                                    <IconButton onClick={() => clickOnButton('remove', item.test_id, item.type)}>
                                                                        <FiX />
                                                                    </IconButton>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>

                                        <div className='cart-detail'>
                                            <div className='grid grid-cols-2'>
                                                <div>
                                                    <p>To be paid | <span>₹ {cartDetail.total || 0}/-</span></p>
                                                </div>

                                                <div className='text-right'>
                                                    <Link to="/cart/checkout">
                                                        <button className='bg-sky-500 my-2 sm:ml-4 hover:bg-sky-500 text-white font-bold py-2 rounded-full w-full sm:w-1/3' type="button" >Proceed</button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <Typography sx={{
                                            textAlign: 'center',
                                            color: '#07aeff'
                                        }}
                                            variant="h6">
                                            Please add tests or packages
                                        </Typography>
                                    </>
                            }

                        </div>
                    </SectionComponent>
        </>
    )
}

export default Cart_Page