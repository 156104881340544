// import { createStore, combineReducers, applyMiddleware } from "redux";
// import thunk from "redux-thunk";
// import { composeWithDevTools } from "@redux-devtools/extension"

import { configureStore } from "@reduxjs/toolkit";
import HomePageSlice from "./Slice/HomepageSlice";
import counterReducer from './Slice/counterSlice'
import PackagePageSlice from "./Slice/PackagePageSlice";
import TestPageSlice from "./Slice/TestPageSlice";
import BlogPageSlice from "./Slice/BlogPageSlice";
import LoginPageSlice from "./Slice/LoginPageSlice";
import DashboardPageSlice from "./Slice/DashboardPageSlice";
import BookingPageSlice from "./Slice/BookingPageSlice";
import CartPageSlice from "./Slice/CartPageSlice";
import CheckoutPageSlice from "./Slice/CheckoutPageSlice";
import UserProfilePageSlice from "./Slice/UserProfilePageSlice";
import PagesSlice from "./Slice/PagesSlice";


// import {
//   HomePageReducer,
// } from './reducers/AllReducers'


// let initialState = {};
// let reducer = combineReducers({
//   HomePageReducer
// });

// const middleware = [thunk];

// const Store = createStore(
//   reducer,
//   initialState,
//   composeWithDevTools(applyMiddleware(...middleware))
// );

// export default Store;


const Store = configureStore({
  reducer: {
    HomePage: HomePageSlice,
    pages: PagesSlice,
    PackagePage: PackagePageSlice,
    TestPage: TestPageSlice,
    BlogPageRed: BlogPageSlice,
    LoginReducer: LoginPageSlice,
    DashboardPage: DashboardPageSlice,
    BookingPage: BookingPageSlice,
    CartPage: CartPageSlice,
    CheckoutPage: CheckoutPageSlice,
    UserProfilePage: UserProfilePageSlice,
    counter: counterReducer,
  }
})

export default Store;