import React, { useEffect, useState } from "react";
import SubHeaderSection from "../components/SubHeaderSection";
import SectionComponent from "../components/SectionComponent";
import { CircularProgress, IconButton, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { bookingDataAPI } from "../redux/Slice/BookingPageSlice";

const Cart_ThankYouPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    
    let type = "0";
    let is_cancel = "0";
    let params = {
      "type": type,
      "is_cancel": is_cancel
    }
    dispatch(bookingDataAPI(params));

  }, [])
  
  return (
    <>
      <SubHeaderSection title={"Thank You"} />
      <SectionComponent>
      <div className="container mx-auto mt-5" style={{ minHeight: "70vh" }}>
            <div className="cart-container">
            <Typography variant='h6' sx={{
                color:'green',
                textAlign:'center',
                fontSize:18
            }}>Thank you for choose <strong>DrPathCare</strong>. We sent you a booking conformation email and sms on your registered detail.</Typography>
            <br />
            <Link to={'/'}>Go To Home</Link>
            </div>
        </div>
      </SectionComponent>
    </>
  );
};

export default Cart_ThankYouPage;
