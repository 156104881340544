import { Box, CircularProgress } from '@mui/material'
import React from 'react'

const ScreenLoader = () => {
    return (
        <>
            <Box sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                background: "#fff",
                zIndex: 99999,
                width: '100%',
                height: '100vh',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                opacity: '.5'
            }}>
                <CircularProgress color="inherit" />
            </Box>
        </>
    )
}

export default ScreenLoader
