import React, { useRef } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Typography } from "@mui/material";

const Testimonials = (props) => {
  const { itemData } = props;

  const sliderRef = useRef();

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    pauseOnHover: true,
  };

  const TestimonialItem = ({ item }) => {
    return (
      <div className="swiper-slide">
        <blockquote className="flex flex-col justify-between h-full p-12">
          <div>
            <div className="flex gap-0.5 theme-text-color">
              {/* item.rating */}
              {[...Array(5)].map((it, ddd) => {
                return (
                  <svg
                    key={ddd}
                    className="w-5 h-5"
                    fill={ddd < item.rating ? "currentColor" : "none"}
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                    />
                  </svg>
                );
              })}
            </div>

            <div className="mt-4">
              <Typography className="text-2xl font-bold theme-text-color sm:text-3xl" color="primary" component={"p"}>
                {item.name}
              </Typography>

              <p className="mt-4 leading-relaxed text-gray-500">
                {item.description}
              </p>
            </div>
          </div>

          <footer className="mt-8 text-sm text-gray-500">
            &mdash; {item.designation}
          </footer>
        </blockquote>
      </div>
    );
  };

  const TestimonialContainer = () => {
    return (
      <Slider {...settings} ref={sliderRef}>
        {itemData.map((item, index) => {
          return (
            <div key={index}>
              <TestimonialItem item={item} />
            </div>
          );
        })}
      </Slider>
    );
  };

  return (
    <>
      <div>
        <div className="grid grid-cols-12 gap-4">
          <div className="md:col-span-5 col-span-12">
            <div className="max-w-xl text-center sm:text-left">
            <Typography component={"h2"}  gutterBottom variant="h9" className="text-3xl font-bold tracking-tight sm:text-4xl" color="primary"  dangerouslySetInnerHTML={{
              __html:`Don't just take our word for it... <br className="hidden sm:block lg:hidden" /> Read reviews from our customers`
            }} />
            
              {/* <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">
                {`Don't just take our word for it...`}
                <br className="hidden sm:block lg:hidden" /> Read reviews from our customers
              </h2> */}

              {/* <p className="mt-4 text-gray-500">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptas
          veritatis illo placeat harum porro optio fugit a culpa sunt id!
        </p> */}
              <div className="md:mt-0 mt-10 lg:mt-8 lg:flex lg:gap-4 gap-10">
                <button
                  aria-label="Previous slide"
                  className="p-3 text-sky-500 border border-sky-500 rounded-full hover:bg-sky-500 hover:text-white prev-button"
                  onClick={() => sliderRef?.current?.slickPrev()}
                >
                  <svg
                    className="w-5 h-5 transform -rotate-180"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 5l7 7-7 7"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                  </svg>
                </button>

                <button
                  aria-label="Next slide"
                  className="p-3 text-sky-500 border border-sky-500 rounded-full hover:bg-sky-500 hover:text-white next-button"
                  onClick={() => sliderRef?.current?.slickNext()}
                >
                  <svg
                    className="w-5 h-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 5l7 7-7 7"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="md:col-span-7 col-span-12">
            <TestimonialContainer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
