import { AiOutlineYoutube } from "react-icons/ai";
import { GrInstagram } from "react-icons/gr";
import { FaFacebookF } from "react-icons/fa";
import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import { Link } from "react-router-dom";
import { WHATSAPP_GREEN, CALL_ICON } from "../../images";
import { base_url } from "../../constants/axiosInstance";

const FooterComponent = () => {

  let gLink = 'https://g.page/r/CVtDIcZlPbtEEB0/review';

  let qLink = [
    {
      "id": 1,
      "name": "Blog",
      "link": "/blogs"
    },
    {
      "id": 2,
      "name": "About Us",
      "link": "/about-us"
    },
    {
      "id": 3,
      "name": "Contact Us",
      "link": "/contact-us"
    },
    {
      "id": 4,
      "name": "Our Lab Partners",
      "link": "/ourlabs"
    },
    {
      "id": 5,
      "name": "Privacy Policy",
      "link": "/privacy-policy"
    },
    {
      "id": 6,
      "name": "Terms & Conditions",
      "link": "/terms-conditions"
    },
    {
      "id": 9,
      "name": "Shipping Delivery",
      "link": "/shipping-delivery"
    },
    {
      "id": 7,
      "name": "Disclaimer",
      "link": "/disclaimer"
    },
    {
      "id": 8,
      "name": "Payment Refund Policy",
      "link": "/payment-refund-policy"
    },
  ];

  return (
    <>

      <div className="googleReviews">

        <a href={gLink} target='_blank'>
          <img alt="google review" src={base_url+"/crm/uploads/Google__G__Logo.svg"} />
          Reviews
        </a>
      </div>

      <footer className="border-t mt-12 pt-12 pb-8 px-4 lg:px-0  bg-gray-900">
        <div className='container  mx-auto'>

          <div className="flex flex-wrap">
            <div className="w-full lg:w-2/5">
              <div>
                <img src="https://image1.jdomni.in/storeLogo/09062022/E8/63/22/446D25BF37F372FE08F9B72C63_1654761939475.png?output-format=webp" className="w-42" alt="logo" />
              </div>
              <p className="text-gray-400 hidden lg:block mt-4 p-0 lg:pr-12">
                Dr.Pathcare is one of The Most Trusted Leading Preventive Healthcare Company and provides highest quality diagnostic tests and health packages to you at the comfort of your home. At Dr.Pathcare , "Quality" is a way of functioning, our stringent quality control parameters
              </p>
            </div>

            <div className="w-full mt-6 lg:mt-0 md:w-1/2 lg:w-1/5">
              <h6 className="font-semibold text-gray-300 mb-4">Quick Links</h6>
              <ul>
                {
                  qLink.map((item, i) => {
                    return (
                      <li key={i} className="my-link text-gray-400 py-0" > <Link to={item?.link} >{item?.name}</Link> </li>
                    )
                  })
                }

              </ul>
            </div>
          </div>

          <hr className="my-6 border-gray-200 sm:mx-auto border-gray-700 lg:my-8" />
          <div className="sm:flex sm:items-center sm:justify-between">
            <span className="text-sm text-gray-500 sm:text-center text-gray-400">© 2023 <a href={"/"} className="hover:underline">DrPathCare™</a>. All Rights Reserved.
            </span>
            <div className="flex mt-4 space-x-6 sm:justify-center sm:mt-0 f-social-link">
              <a href="https://www.facebook.com/DrPathcare.india" target="blank" className="text-gray-500 hover:text-gray-900 hover:text-white">
                {/* <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd"></path></svg> */}
                <FaFacebookF />
                <span className="sr-only">Facebook</span>
              </a>
              <a href="https://www.instagram.com/dr.pathcare/" target="blank" className="text-gray-500 hover:text-gray-900 hover:text-white">
                {/* <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fillRule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clipRule="evenodd"></path></svg> */}
                <GrInstagram />
                <span className="sr-only">Instagram</span>
              </a>
              <a href="https://www.youtube.com/channel/UCO9riY8H1d9fGA4K3iCOsEg" target="blank" className="text-gray-500 hover:text-gray-900 hover:text-white">
                {/* <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path></svg> */}
                <AiOutlineYoutube />
                <span className="sr-only">Youtube</span>
              </a>
            </div>
          </div>
        </div>
      </footer>
      <div class="call_us_icon">
        <a href="tel:918447007749" >
          <img src={CALL_ICON} width="50" />
        </a>
      </div>
      <div class="whatsapp">
        <a className="hidden md:block" href="https://web.whatsapp.com/send?phone=918447007749&text=Hello, \n" target="_blank"><img src={WHATSAPP_GREEN} width="50" /></a>
        <a className="md:hidden block" href="https://wa.me/phone=918447007749&text=Hello, \n" target="_blank"><img src={WHATSAPP_GREEN} width="50" /></a>
      </div>
    </>
  )
}

export default FooterComponent
