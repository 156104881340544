import React, { useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  Typography
} from "@mui/material"
import SectionComponent from '../../components/SectionComponent'
import SectionHeading from '../../components/SectionHeading'

import { useDispatch, useSelector } from 'react-redux'
import AlertComponent from '../../components/AlertComponent'
import { loginDataAPI, otpVerifyDataAPI } from '../../redux/Slice/LoginPageSlice'
import AuthCode from 'react-auth-code-input'
import OTPInput from 'react-otp-input'
import ScreenLoader from '../../components/ScreenLoader'
import { userProfileDataAPI } from '../../redux/Slice/UserProfilePageSlice'
import { useNavigate } from 'react-router-dom'

const LoginPage = () => {


  const {
    data,
    loading,
    verifyLoading,
    msg,
    msgType,
  } = useSelector(state => state.LoginReducer);

  const { isAuth } = useSelector((state) => state.UserProfilePage);

  const dispatch = useDispatch();

  const [IsPageLoading, setIsPageLoading] = useState(false)
  // const [OTPDiv, setOTPDiv] = useState(false)
  const [OTPDiv, setOTPDiv] = useState(false)
  // const [Inputs, setInputs] = useState({ mobile: 7894561230 })
  const [Inputs, setInputs] = useState({})
  const [otp, setOtp] = useState(null)
  const [InputsError, setInputsError] = useState("");

  const handleChange = (otp0) => {
    setInputsError('');
    setOtp(otp0);
    setInputs({ ...Inputs, otp: otp0 });
  };

  const changeInput = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
    setInputs({ ...Inputs, [event.target.name]: event.target.value })
  }

  const [result, setResult] = useState();
  const handleOnChange0000 = (res) => {
    setResult(res);
  };

  const loginClick = () => {
    let params = {
      mobile: Inputs.mobile
    }
    dispatch(loginDataAPI(params));

    // let loginData = loginRequest({
    //   mobile:Inputs.mobile
    // });
    // loginData = loginData.data;
    // let msgType0 = "success";
    // if (!loginData?.status) {
    //   msgType0 = "error";
    // }

    // dispatch(loginUserReset());

    // dispatch(
    //   loginUser({
    //     data: loginData.data,
    //     loading: false,
    //     msg: loginData.msg,
    //     msgType: msgType0,
    //   })
    // );

    // if(loginData?.status === true){
    // setOTPDiv(true);
    // }
  }

  const otpVerifyClick = () => {
    if (!Inputs.otp) {
      setInputsError('Please enter OTP');
      return false;
    } else
      if (Inputs.otp.length !== 6) {
        setInputsError('Please enter valid OTP');
        return false;
      } else {
        setInputsError('');
      }
    console.log(Inputs)
    dispatch(otpVerifyDataAPI(Inputs));
  }

  const navigate = useNavigate();

  useEffect(() => {
    if (!loading) {
      setOTPDiv(true);
    }
  }, [loading])

  useEffect(() => {
    if (!verifyLoading && msgType === 'success') {
      setIsPageLoading(true);
      setTimeout(() => {
        dispatch(userProfileDataAPI({ token: data?.token }))
        // window.location.replace(window.location.host)
      }, 2000);
    }
  }, [verifyLoading])

  useEffect(() => {
    let parts = window.location.href.split('/');
    let lastSegment = parts.pop() || parts.pop();
    
    if (isAuth == true && lastSegment.includes("login")) {
      navigate('/');
    }
    
  }, [isAuth])

  return (
    <>
      {
        IsPageLoading &&
        <ScreenLoader />
      }
      <SectionComponent>
        <div className="flex justify-center items-center flex-wrap h-full g-6 text-gray-800">
          <div className="md:w-8/12 lg:w-6/12 mb-12 md:mb-0">
            <img
              src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg"
              className="w-full"
              alt="Phone image"
            />
          </div>
          <div className="w-full lg:w-5/12 lg:ml-20">
            <form>
              <div className="mb-3">
                <SectionHeading heading="Login" />
              </div>

              {/* <div className="mb-6">
                  <AlertComponent 
                    msgType={msgType}
                    msg={msg}
                  />
                </div>
                {
                  InputsError && (
                    <div className="mb-6">
                      <AlertComponent 
                        msgType={'error'}
                        msg={InputsError}
                      />
                    </div>
                  )
                } */}

              {
                InputsError && (
                  <div className="mb-6">
                    <AlertComponent
                      msgType={'error'}
                      msg={InputsError}
                    />
                  </div>
                )
              }
              {
                (msgType && msg) && (
                  <div className="mb-6">
                    <AlertComponent
                      msgType={msgType}
                      msg={msg}
                    />
                  </div>
                )
              }



              {/* <div class="bg-teal-100 border border-teal-400 text-teal-700 px-4 py-3 rounded relative" role="alert">
                <strong class="font-bold">Holy smokes!</strong>
                <span class="block sm:inline">Something seriously bad happened.</span>
                <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
                  <svg class="fill-current h-6 w-6 text-teal-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
                </span>
              </div> */}

              {
                OTPDiv === false && (
                  <div className="mb-6">
                    <label className="font-bold">Mobile Number</label>
                    <input
                      type="text"
                      className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-sky-600 focus:outline-none"
                      placeholder="Enter Phone Number"
                      onChange={(e) => { changeInput(e); }}
                      name="mobile"
                      maxLength={10}
                      value={Inputs['mobile']}
                    />
                  </div>
                )
              }


              {
                OTPDiv === true && (
                  <>
                    <div className="mb-2 otpInput-container">
                      <label className="font-bold">Enter OTP</label>
                      <OTPInput
                        inputClassName="otpInput"
                        numInputs={6}
                        isDisabled={false}
                        hasErrored={false}
                        errorStyle="error"
                        className="otp__"
                        onChange={(vl) => handleChange(vl)}
                        separator={<span>{"-"}</span>}
                        isInputNum={1}
                        isInputSecure={false}
                        shouldAutoFocus={false}
                        value={otp}
                        renderInput={(props) => <input className='otpInput' {...props} />}
                        placeholder={"******"}
                        inputStyle={{
                          width: "3rem",
                          height: "3rem",
                          margin: "0 1rem",
                          fontSize: "2rem",
                        }}
                      />
                    </div>
                    <div className="flex justify-between">
                      <div className="mb-6 cursor-pointer" onClick={() => { setOTPDiv(false); }}>
                        <a className="font-bold text-green-500 hover:text-green-600">Change Mobile Number</a>
                      </div>
                      
                      <div className="mb-6 cursor-pointer" onClick={() => loginClick()}>
                        <a className="font-bold text-sky-500 hover:text-sky-500">Resend Otp</a>
                      </div>
                    </div>
                  </>
                )
              }


              {
                OTPDiv === true ?
                  <>
                    <button
                      type="button"
                      onClick={() => otpVerifyClick()}
                      className="inline-block px-7 py-3 bg-sky-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out w-full"
                      data-mdb-ripple="true"
                      data-mdb-ripple-color="light"
                    >
                      Verify OTP
                    </button>
                  </>
                  :
                  <>
                    <button
                      type="button"
                      onClick={() => loginClick()}
                      className="inline-block px-7 py-3 bg-sky-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out w-full"
                      data-mdb-ripple="true"
                      data-mdb-ripple-color="light"
                    >
                      Login
                    </button>
                  </>
              }

            </form>
          </div>
        </div>
      </SectionComponent>
    </>
  )
}

export default LoginPage