import Layout from './Layout';
import UserLayout from './UserLayout';
import HomePage from './HomePage';
import PackagesPage from './PackagesPage';
import PackagesDetailPage from './PackagesDetailPage';
import TestsPage from './TestsPage';
import OurLabsPage from './OurLabsPage';
import ContactUsPage from './ContactUsPage';
import MakePayment from './MakePayment';
import BlogsPage from './BlogsPage';
import Cart_Page from './Cart_Page';
import Cart_CheckOutPage from './Cart_CheckOutPage';
import BlogDetailPage from './BlogDetailPage';
import Cart_ThankYouPage from './Cart_ThankYouPage';
import AboutUsPage from './AboutUsPage';
import TermsConditionsPage from './TermsConditionsPage';
import PrivacyPolicyPage from './PrivacyPolicyPage';
import PaymentRefundPolicyPage from './PaymentRefundPolicyPage';
import ShippingDeliveryPage from './ShippingDeliveryPage';
import DisclaimerPage from './DisclaimerPage';
import LoginPage from './auth/LoginPage';


export default {
    Layout,
    UserLayout,
    HomePage,
    PackagesPage,
    PackagesDetailPage,
    TestsPage,
    OurLabsPage,
    AboutUsPage,
    ContactUsPage,
    MakePayment,
    BlogsPage,
    BlogDetailPage,
    Cart_ThankYouPage,
    Cart_Page,
    Cart_CheckOutPage,
    TermsConditionsPage,
    PrivacyPolicyPage,
    PaymentRefundPolicyPage,
    ShippingDeliveryPage,
    DisclaimerPage,
    LoginPage
}