import React, { Fragment, useEffect, useState } from 'react'
import {
  Button,
  Container,
  Box,
  Typography
} from "@mui/material"
import { useDispatch, useSelector } from 'react-redux'
import { HomePageAction } from '../redux/actions/AllActions';
import { getHomeData, homeDataAPI } from '../redux/Slice/HomepageSlice';
import { decrement, increment } from '../redux/Slice/counterSlice';
import { Helmet } from 'react-helmet-async';

import HomeSlider from '../components/HomeSlider';
import SectionComponent from '../components/SectionComponent';
import PackageCard from '../components/PackageCard';
import { Link } from 'react-router-dom';
import CustomButton from '../components/CustomButton';
import DiseasesCard from '../components/DiseasesCard';
import VideosCard from '../components/VideosCard';
import VideoModel from '../components/VideoModel';
import Testimonials from '../components/Testimonials';
import Cookies from 'js-cookie';
import EnquiryModal from '../components/EnquiryModal';

const HomePage = () => {
  const {
    data, loading
  } = useSelector((state) => state.HomePage)

  const dispatch = useDispatch();
  const [HomeData, setHomeData] = useState({})
  const [MetaDetails, setMetaDetails] = useState({})
  const [isOpen, setOpen] = useState(false);
  const [ytCode, setYtCode] = useState("");
  // let HomeData = {};
  let defaultData = {};
  useEffect(() => {
    if (loading) {
      dispatch(homeDataAPI());
    }
    if (!loading) {
      setHomeData(data.data);
      defaultData = data.data;
      setMetaDetails(data?.meta_details)
      // console.log('data >>>>> ', data.data)
      // console.log('defaultData?.meta_details?.meta_title >>>>> ', data?.meta_details?.meta_title)
    }
  }, [loading])


  return (
    <>
      
      <EnquiryModal />

      <Helmet>
        <title>{MetaDetails?.meta_title}</title>
        <meta
          name="description"
          content={MetaDetails?.meta_description}
        />
        <meta name="keywords" content={MetaDetails?.meta_keyword} />
      </Helmet>
      <Box>
        <HomeSlider ItemData={HomeData?.banner?.item_data} />
      </Box>
      <SectionComponent heading={HomeData?.packages?.title} >
        <>
          <div className="p-3 sm:p-5 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
            {
              HomeData?.packages?.item_data.map((item, index) => {
                return (
                  <PackageCard key={index} itemData={item} />
                )
              })
            }
          </div>

          <div className="grid-cols-12 text-center">
            <Link to='./packages'>
              <CustomButton >View All</CustomButton>
            </Link>
          </div>

        </>
      </SectionComponent>


      {HomeData?.whyUs && (
        <>
          <SectionComponent className={"theme-light-bg"} heading={"WHY DR PATHCARE?"} subHeading={"The facilities we offer for your convenience."} >
            <>
              <div className="p-3 sm:p-5 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-5 ">
                {
                  HomeData?.whyUs.item_data.map((item, index) => {
                    return (
                      <div className="text-center" key={index}>
                        {
                          item.image && (
                            <>
                              <img alt="" src={item.imageUrl} style={{
                                width: 100,
                                height: 100,
                                margin: '0 auto'
                              }} />
                              {/* <ImageLoader
                                srcLink={removeImageName(item.imageUrl, item.image)}
                                src={item.image}
                                alt="Picture of the author"
                                width={100}
                                height={100}
                              /> */}
                            </>
                          )
                        }
                        <p className="pb-0 font-medium">
                          {item.name}
                        </p>
                      </div>

                    )
                  })
                }
              </div>


            </>
          </SectionComponent>

        </>
      )}

      {HomeData?.aboutUs && (
        <>
          <SectionComponent heading={"About Us"} >
            <div className="p-3 sm:p-5 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-5 ">
              <div className="text-center" style={{ position: "relative" }}>
                {
                  HomeData?.aboutUs?.item_data[0].image && (
                    <>
                      <img
                        alt="About Us"
                        src={HomeData?.aboutUs?.item_data[0].imageUrl}
                        style={{
                          width: 500,
                          height: 250,
                          margin: '0 auto'
                        }}
                      />
                    </>
                  )
                }
              </div>
              <div className="text-left flex items-center text-base md:text-md lg:text-xl">
                <div dangerouslySetInnerHTML={{ __html: HomeData?.aboutUs?.item_data[0]?.description }} />
              </div>
            </div>
          </SectionComponent>
        </>
      )}

      {HomeData?.testCategory && (
        <>
          <SectionComponent heading={HomeData?.testCategory?.title} subHeading={HomeData?.testCategory?.subTitle}>
            <div className="p-3 sm:p-5 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-7 gap-5">
              {
                HomeData?.testCategory?.item_data && HomeData?.testCategory?.item_data.map((item, index) => {
                  return (
                    <DiseasesCard itemData={item} key={index} />
                  )
                })
              }
            </div>
          </SectionComponent>
        </>
      )}

      {HomeData?.videos && (
        <>
          <VideoModel isOpen={isOpen} setOpen={setOpen} ytCode={ytCode} />
          <SectionComponent className={"theme-light-bg"} heading="Our Videos" >
            <div className="p-3 sm:p-5 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
              {
                HomeData?.videos.item_data.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <VideosCard itemData={item} setYtCode={setYtCode} setOpen={setOpen} />
                    </Fragment>
                  )
                })
              }
            </div>
          </SectionComponent>
        </>
      )}
      {HomeData?.usersSay && (
        <>
          <SectionComponent heading={HomeData?.usersSay.title} >
            <div className="p-3 sm:p-5"></div>
            <Testimonials itemData={HomeData?.usersSay.item_data} />
          </SectionComponent>

          {/* <section className="py-5 theme-light-bg ">
              <div className="container mx-auto">
                <div className="grid-cols-12">
                  <Heading
                    heading={HomeData?.usersSay.title}
                    className="text-center mt-5 text-4xl font-bold theme-text-color"
                  />
                </div>
                <div className="p-3 sm:p-5"></div>
                      <Testimonials itemData={HomeData?.usersSay.item_data} />
              </div>
            </section> */}

        </>
      )}

    </>
  )
}

export default HomePage