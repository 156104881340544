import { AiOutlineArrowLeft } from "react-icons/ai";
import { FaRegUser } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { BiCalendar } from "react-icons/bi";
import { RxDashboard } from "react-icons/rx";
import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { LOGO } from '../images';
import CustomButton from "../components/CustomButton";
import { useDispatch } from "react-redux";
import { userLogout } from "../components/layout/HeaderComponent";

const drawerWidth = 240;

function UserLayout(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const sideBarMenu = [
    {
      title: "Dashboard",
      link: "/account",
      icon: <RxDashboard />,
      id: "1",
    },
    {
      title: "My Profile",
      link: "/account/profile",
      icon: <FaRegUser />,
      id: "3",
    },
    {
      title: "My Booking",
      link: "/account/my-booking",
      icon: <BiCalendar />,
      id: "2",
    },
    // {
    //   title:"Transactions",
    //   link:"#0",
    //   icon:"",
    //   id:"3",
    // },
    {
      title: "Logout",
      link: "#0",
      icon: <FiLogOut />,
      id: "4",
    },
  ]

  const dispatch  = useDispatch();
  const navigate = useNavigate();

  const drawer = (
    <div>
      <Toolbar className='sideBarLogo'>
        <Link to="/">
          <img alt="LOGO" src={LOGO} />
        </Link>
      </Toolbar>
      <Divider />

      <List>
        {sideBarMenu.map((text, index) => {

          return (
            <ListItem key={index} disablePadding>
              <ListItemButton onClick={() => {
                if(text?.id == 4){
                  userLogout(dispatch);
                }else{
                  navigate(text?.link)
                }
              }}>
                <ListItemIcon>
                  {text.icon}
                </ListItemIcon>
                <ListItemText primary={text?.title} />
              </ListItemButton>
            </ListItem>
          )
        })}
      </List>

      {/* <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
    </div>
  );


  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <CustomButton size="small" onClick={(e) => {
            e.preventDefault();
            navigate('/');
          }} ><AiOutlineArrowLeft style={{
            marginRight: '.4rem'
          }} /> Back To Home</CustomButton>
          {/* <Typography variant="h6" noWrap component="div">
            Responsive drawer
          </Typography> */}
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}

UserLayout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default UserLayout;
