import { Container } from '@mui/material'
import React from 'react'

const SubHeaderSection = ({ title }) => {
  return (
    <>
      <div className='bg-sky-300 h-[10rem] justify-center flex items-center subHeader'>
        <Container maxWidth="xl" sx={{
          textAlign: 'center'
        }}>
          <h1 className='text-3xl font-bold text-white capitalize'>{title}</h1>
        </Container>
      </div>
    </>
  )
}

export default SubHeaderSection