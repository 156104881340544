import React from "react";
import CustomButton from "./CustomButton";

const VideosCard = ({itemData, setYtCode, setOpen}) => {



  return (
    <>
      <div className="rounded overflow-hidden shadow-lg custom-card-1 hover:bg-gray-50">
        <div className="card-image">
        <img 
            alt={itemData.title}
            src={itemData.imageUrl}
            style={{
              width: '100%',
              maxWidth: 500,
              height: 300,
              margin: '0 auto'
            }} 
          />
        {/* <ImageLoader
            srcLink={removeImageName(itemData.imageUrl, itemData.image)}
            src={itemData.image}
            alt={itemData.title}
            width={500}
            height={300}
        /> */}
        </div>
        <div className="card-content p-5 pb-0">
          <h3 className="text-lg">
            <a className="no-underline hover:underline text-black text-center" href="#">
            {itemData.title}
            </a>
          </h3>
        </div>
        
        <div className="card-footer unset sm:flex p-5 pt-0">
          <CustomButton variant={'outlined'} onClick={()=>{
            setYtCode(itemData.yt_link);
            setOpen(true);  
          }} fullWidth>
            Watch Now
          </CustomButton>
          {/* <button onClick={()=>{
            setYtCode(itemData.yt_link);
            setOpen(true);
          }} className="border-2 border-sky-500  my-2 sm:mr-4 hover:bg-sky-500 hover:text-white text-sky-500 font-bold py-2 rounded-full w-full">
            Watch Now
          </button> */}
        </div>
      </div>
    </>
  );
};

export default VideosCard;
