import React, { useEffect, useState } from 'react'
import SubHeaderSection from '../components/SubHeaderSection'
import { useDispatch, useSelector } from 'react-redux';
import { packageDataAPI } from '../redux/Slice/PackagePageSlice';
import SectionComponent from '../components/SectionComponent';
import PackageCard from '../components/PackageCard';
import { CircularProgress } from '@mui/material';

const PackagesPage = () => {
    const {
        data, loading
    } = useSelector((state) => state.PackagePage)

    const dispatch = useDispatch();

    const [returnData, setReturnData] = useState(null)

    useEffect(() => {
        dispatch(packageDataAPI());
    }, [])
    useEffect(() => {
        if (!loading) {
            setReturnData(data.data);
            console.log('data >>>>> ', data.data)
        }
    }, [loading])



    return (
        <>
            <SubHeaderSection title={returnData ? returnData.title : "Packages"} />
            {
                loading ?
                    <CircularProgress />
                    :
                    <SectionComponent >
                        <div className="sm:flex items-start  gap-4">
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 w-full gap-4">
                                {
                                    returnData && returnData?.item_data.map((item, i) => {
                                        return (
                                            <PackageCard key={i} itemData={item} />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </SectionComponent>
            }
        </>
    )
}

export default PackagesPage