import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../constants/axiosInstance";
import { getCrtToken } from "../../constants/randomFunctions";


export const homeDataAPI = createAsyncThunk(
    'homeData',
    async (params={}, thunkAPI) => {
      
      const response = await axiosInstance(getCrtToken).post(
        "/home-data",
        params
      )
      return response.data
    }
  )

const HomePageSlice = createSlice({
    name: "HomePage",
    initialState: {
        loading: true,
        data:[],
        error:null,
        message:null,
    },
    reducers:{
        getHomeData: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        }
    },
    // extraReducers: {
    //     [homeDataAPI.fulfilled]:(state, action) => {
    //         state.loading = false;
    //         state.data = action.payload;
    //         // state.data = action.payload;
    //     }
    // }
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(homeDataAPI.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            // Add user to the state array
            // state.entities.push(action.payload)
        })
      },
})

export const {
    getHomeData
} = HomePageSlice.actions;

export default HomePageSlice.reducer;