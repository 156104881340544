import React, { useEffect, useState } from 'react'
import SubHeaderSection from '../components/SubHeaderSection'
import { useDispatch, useSelector } from 'react-redux';
import { packageDataAPI } from '../redux/Slice/PackagePageSlice';
import SectionComponent from '../components/SectionComponent';
import PackageCard from '../components/PackageCard';
import { CircularProgress } from '@mui/material';
import { pageDataAPI } from '../redux/Slice/PagesSlice';


const DisclaimerPage = () => {
    const {data, loading} = useSelector(state=>state.pages);
    const [Pages, setPages] = useState(null)
    const dispatch = useDispatch()
    useEffect(() => {
      if(loading){
        dispatch(pageDataAPI())
      }
      if(!loading){ 
        let crtPage = data.data.find(item=>item.id === 5);
        setPages(crtPage)
      }
    }, [loading])
    return (
        <>
            <SubHeaderSection title={"Disclaimer"} />
            <SectionComponent >
                <div className="p-3 sm:p-5 grid grid-cols-1 gap-5 cst-text">
                    
                <div dangerouslySetInnerHTML={{__html: (Pages?.description || "")}} />
                    {/* <div>

                    <h2>To be updated...</h2>
                    </div> */}
                </div>
            </SectionComponent>
        </>
    )
}

export default DisclaimerPage