import React, { useEffect, useState } from 'react'
import SubHeaderSection from '../components/SubHeaderSection'
import { useDispatch, useSelector } from 'react-redux';
import { packageDataAPI } from '../redux/Slice/PackagePageSlice';
import SectionComponent from '../components/SectionComponent';
import PackageCard from '../components/PackageCard';
import { CircularProgress } from '@mui/material';
import { ISO_14001_2015, ISO_45001_2018, ISO_9001_2015 } from '../images';

const AboutUsPage = () => {

    return (
        <>
            <SubHeaderSection title={"About Us"} />
            <SectionComponent >
                <div className="p-3 sm:p-5 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-5 ">
                    <div className="text-center" style={{ position: "relative" }}>
                        <img alt="logo" src={'https://image3.jdomni.in/banner/10062022/AF/91/3A/5E4B0625C5F165AB98229D6837_1654859727350.png?output-format=webp'} />
                    </div>
                    <div className="text-left flex items-center flex-col justify-center">
                        <p className="text-base md:text-md lg:text-xl">
                            Dr.Pathcare is one of The Most Trusted Leading Preventive
                            Healthcare Company and provides highest quality diagnostic
                            tests and health packages to you at the comfort of your
                            home. At Dr.Pathcare , "Quality" is a way of functioning,
                            our stringent quality control parameters (AIIMS, CMC
                            Vellore certified quality assessments, NABL compliant
                            SOPs, ISO certified testing facilities, US FDA/CE approved
                            equipment etc.) coupled with technology enabled systems
                            ensure the consistent reproducibility of results and
                            reports. Our tele-consultation services from India's most
                            prestigious medical colleges and Clinical Nutritionists
                            and dieticians make staying healthy convenient and hassle
                            free.
                        </p>
                    </div>
                </div>
            </SectionComponent>
            <SectionComponent >
                <div className="p-3 sm:p-5 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-5 ">
                    <div className="text-center" style={{ position: "relative", border: '1px solid #ccc', borderRadius: 10, overflow: 'hidden' }}>
                        <a href={ISO_9001_2015} target="_blank" rel="noopener noreferrer">
                            <img alt="logo" src={ISO_9001_2015} />
                        </a>
                    </div>
                    <div className="text-center" style={{ position: "relative", border: '1px solid #ccc', borderRadius: 10, overflow: 'hidden' }}>
                        <a href={ISO_45001_2018} target="_blank" rel="noopener noreferrer">
                            <img alt="logo" src={ISO_45001_2018} />
                        </a>
                    </div>
                    <div className="text-center" style={{ position: "relative", border: '1px solid #ccc', borderRadius: 10, overflow: 'hidden' }}>
                        <a href={ISO_14001_2015} target="_blank" rel="noopener noreferrer">
                            <img alt="logo" src={ISO_14001_2015} />
                        </a>
                    </div>
                </div>
            </SectionComponent>
            {/* <SectionComponent >
                <div className="p-3 sm:p-5 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-5 ">
                    <div className="text-left flex items-center flex-col justify-center">
                        <h3 className='text-3xl'>Quality Management System</h3>
                        <p className="text-center md:text-md lg:text-xl">
                            DR PATHCARE is certified by the International Standard Organisation, ISO 9001 certification for Quality Management System in the year 2021 for providing pathology lab diagnostic services.
                        </p>
                    </div>
                    <div className="text-center" style={{ position: "relative" }}>
                        <img alt="logo" src={'https://image3.jdomni.in/banner/10062022/AF/91/3A/5E4B0625C5F165AB98229D6837_1654859727350.png?output-format=webp'} />
                    </div>
                </div>
            </SectionComponent> */}
        </>
    )
}

export default AboutUsPage