import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../constants/axiosInstance";
import { getCrtToken } from "../../constants/randomFunctions";


export const packageDataAPI = createAsyncThunk(
  'packageData',
  async (params = {}, thunkAPI) => {
    const response = await axiosInstance(getCrtToken).post(
      "/package-list",
      params
    )
    return response.data
  }
)
export const singlePackageDataAPI = createAsyncThunk(
  'singlePackageData',
  async (params = {}, thunkAPI) => {
    const response = await axiosInstance(getCrtToken).post(
      "/package-list",
      params
    )
    return response.data
  }
)

const PackagePageSlice = createSlice({
  name: "PackagePage",
  initialState: {
    loading: true,
    data: [],
    singleData: [],
    error: null,
    message: null,
  },
  reducers: {
    getPackageData: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(packageDataAPI.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });

    // Single Package API
    builder.addCase(singlePackageDataAPI.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(singlePackageDataAPI.fulfilled, (state, action) => {
      state.loading = false;
      state.singleData = action.payload;
    });
    builder.addCase(singlePackageDataAPI.rejected, (state, action) => {
      state.loading = false;
      state.singleData = [];
      state.error = action.payload;
    });
  },
})

export const {
  getPackageData
} = PackagePageSlice.actions;

export default PackagePageSlice.reducer;