import Cookies from 'js-cookie';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from './CustomButton';
import { cartDataAPI } from '../redux/Slice/CartPageSlice';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// import axiosInstance from '../constants/axiosInstance';
// import { cartData, cartDataRequest } from '../store/Slices/cartSlice';

const AddToCartButton = (props) => {

  const { type, itemId, extClassName } = props;
  const { isAuth } = useSelector((state) => state.UserProfilePage);
  const dispatch = useDispatch();
  // const { data,
  //         loading,
  //         msg,
  //         msgType
  //       } = useSelector(state=>state.cartDataReducer);


  const {
    data,
    loading,
    msg,
    msgType
  } = useSelector((state) => state.CartPage);


  const clickOnButton = async (actionType) => {
    // console.log('actionType >>>', actionType)

    let params = {
      "source": "web",
      "type": actionType,
      "for": type,
      "test_id": itemId
    }

    dispatch(cartDataAPI(params))

  }
  const navigate = useNavigate();

  const [Items, setItems] = useState([]);

  const checkBtnStatus = () => {
    let a00 = [];
    data && data.length > 0 && data.map((item0) => {
      a00.push(`${item0.type}_${item0.test_id}`);
    })
    setItems(a00)
  }

  useEffect(() => {
    if (!loading) {
      checkBtnStatus()
    }
  }, [dispatch, loading])


  // if (loading) {
  //   return (
  //     <>
  //       <CustomButton variant="contained" disable>
  //         <CircularProgress color="inherit" />
  //       </CustomButton>
  //     </>
  //   )
  // }

  return (
    <>
      {
        isAuth ?
          <>
            {
              Items.includes(`${type}_${itemId}`) ?
                <>
                  <CustomButton color="error" onClick={() => clickOnButton('remove')}>Remove </CustomButton>
                  {/* <button onClick={() => clickOnButton('remove')} className={`bg-red-500 my-2 sm:ml-4 hover:bg-red-500 text-white font-bold py-2 rounded-full w-full ${extClassName}`}>Remove</button> */}
                </>
                :
                <>
                  <CustomButton onClick={() => clickOnButton('add')}>Add To Cart</CustomButton>
                  {/* <button onClick={() => clickOnButton('add')} className={`bg-sky-500 my-2 sm:ml-4 hover:bg-sky-500 text-white font-bold py-2 rounded-full w-full ${extClassName}`}>Add To Cart</button> */}
                </>
            }
          </>
          :
          <>
            <CustomButton onClick={() => navigate('/login')}>Add To Cart</CustomButton>
            {
              Items.includes(`${type}_${itemId}`) ?
                <>
                  {/* <button onClick={() => {
                  window.location.href = '/login';
                }} className={`bg-red-500 my-2 sm:ml-4 hover:bg-red-500 text-white font-bold py-2 rounded-full w-full ${extClassName}`}>Remove</button> */}
                </>
                :
                <>
                  {/* <button onClick={() => {
                  window.location.href = '/login';
                }} className={`bg-sky-500 my-2 sm:ml-4 hover:bg-sky-500 text-white font-bold py-2 rounded-full w-full ${extClassName}`}>Add To Cart</button> */}
                </>
            }
          </>

      }

      {/* <button onClick={() => clickOnButton('add')} className={`bg-sky-500 my-2 sm:ml-4 hover:bg-sky-500 text-white font-bold py-2 rounded-full w-full ${extClassName}`}>Add To Cart</button> */}

      {/* <CustomButton size={"small"}>
        Add To
      </CustomButton> */}

    </>
  )
}

export default AddToCartButton
