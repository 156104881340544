import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../constants/axiosInstance";
import { getCrtToken } from "../../constants/randomFunctions";


export const checkoutDataAPI = createAsyncThunk(
    'checkoutData',
    async (params = {}, thunkAPI) => {
        const response = await axiosInstance(getCrtToken).post(
            "/customer/cart/checkout",
            params
        )
        return response?.data
    }
)

const CheckoutPageSlice = createSlice({
    name: "CheckoutPage",
    initialState: {
        data: [],
        loading: true,
        msg: "",
        error: "",
        msgType: "",
    },
    reducers: {
        checkoutDataEmpty: (state, action) => {
            state.data = [];
            state.loading = true;
            state.msg = "";
            state.msgType = "";
        },
    },
    extraReducers: (builder) => {

        builder.addCase(checkoutDataAPI.pending, (state, action) => {
            state.loading = true;
            state.error = false;
            state.msgType = null;
        });
        builder.addCase(checkoutDataAPI.fulfilled, (state, action) => {
            state.data = action.payload?.data;
            state.loading = false;
            state.msg = action.payload?.msg;
            state.msgType = (action.payload?.msgType || 'success');
        });
        builder.addCase(checkoutDataAPI.rejected, (state, action) => {
            state.loading = false;
            state.error = true;
            state.msgType = "error";
            state.msg = action.payload?.msg;
            state.data = action.payload;
        });
    },
})

export const {
    checkoutDataEmpty
} = CheckoutPageSlice.actions;

export default CheckoutPageSlice.reducer;