import { Button } from '@mui/material'
import React from 'react'

const CustomButton = ({ variant, children, fullWidth, ...props }) => {

  return (
    <>
      <Button className='common-btn' {...props} fullWidth={fullWidth} variant={variant || 'contained'}
        sx={{
          color: ((!variant || variant == "contained") ? "#fff" : ""),
          fontWeight: "700",
          borderRadius: '50px',
        }}>
        {children}
      </Button>
    </>
  )
}

export default CustomButton