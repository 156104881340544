import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../constants/axiosInstance";
import Cookies from "js-cookie";
import ContextComponent from "../../context/ContextComponent";
import { getCrtToken } from "../../constants/randomFunctions";


export const loginDataAPI = createAsyncThunk(
  'loginData',
  async (params = {}, thunkAPI) => {
    const response = await axiosInstance(getCrtToken).post(
      "/auth/login",
      params
    )
    return response.data
  }
)

export const otpVerifyDataAPI = createAsyncThunk(
  '/auth/otpVerify',
  async (params = {}, thunkAPI) => {
    
    const response = await axiosInstance(getCrtToken).post(
      "/auth/otp-verify",
      params
    )
    if (response.data?.status) {
      // console.log('UserToken >>>> ', response.data?.token);
      
      Cookies.set('token', response.data?.token)
      Cookies.set('token_111', response.data?.token)
    }
    return response.data
  }
)

const LoginPageSlice = createSlice({
  name: "LoginPage",
  initialState: {
    loading: true,
    verifyLoading: true,
    data: [],
    error: null,
    msgType: null,
    msg: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Login OR Register 
    builder.addCase(loginDataAPI.pending, (state, action) => {
      state.loading = true;
      state.msgType = null;
      state.error = false;
    });
    builder.addCase(loginDataAPI.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.msgType = "success";
      state.msg = action.payload?.msg;
      state.data = action.payload;
    });
    builder.addCase(loginDataAPI.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.msgType = "error";
      state.msg = action.payload?.msg;
      state.data = action.payload;
    });

    // OTP Verify
    builder.addCase(otpVerifyDataAPI.pending, (state, action) => {
      state.verifyLoading = true;
      state.msgType = null;
      state.error = false;
      state.msgType = null;
      state.msg = null;
    });
    builder.addCase(otpVerifyDataAPI.fulfilled, (state, action) => {
      let mT = "success";
      if(action.payload?.status === false){
        mT = "error"
      }
      state.verifyLoading = false;
      state.error = false;
      state.msgType = mT;
      state.msg = action.payload?.msg;
      state.data = action.payload;
    });
    builder.addCase(otpVerifyDataAPI.rejected, (state, action) => {
      state.verifyLoading = false;
      state.error = true;
      state.msgType = "error";
      state.msg = action.payload?.msg;
      state.data = action.payload;
    });

  },
})

export const {
  getLoginData
} = LoginPageSlice.actions;

export default LoginPageSlice.reducer;