import React, { useEffect, useState } from 'react'
import SubHeaderSection from '../components/SubHeaderSection'
import { useDispatch, useSelector } from 'react-redux';
import { packageDataAPI } from '../redux/Slice/PackagePageSlice';
import SectionComponent from '../components/SectionComponent';
import PackageCard from '../components/PackageCard';
import { CircularProgress } from '@mui/material';
import moment from 'moment/moment';
import { useParams } from 'react-router-dom';

const BlogDetailPage = () => {
    const params  = useParams();
  let slug = params.slug;
    
    const {
        data, loading
      } = useSelector((state) => state.BlogPageRed)
    const [detail, setDetail] = useState(null);
    useEffect(() => {
        let data0 = data.data.find(item=>item.slug === slug);
        setDetail(data0)
        console.log('slug >>> ', data0)
    }, [])
    


    return (
        <>
            <SubHeaderSection title={detail?.name || "Blog Detail"} />
            <SectionComponent >
                <div className="max-w-screen-lg mx-auto">
                        <div className="mb-4 md:mb-0 w-full mx-auto relative">
                            <div className="px-4 lg:px-0">
                                <h2 className="text-4xl font-semibold text-gray-800 leading-tight">
                                    {detail?.name}
                                </h2>
                                <a className="py-2 text-green-700 inline-flex items-center justify-center mb-2">
                                    {detail?.created_at
                                        ? moment(detail?.created_at).format("DD MMM, YYYY")
                                        : ""}
                                </a>
                            </div>

                            <img
                                src={detail?.imageUrl}
                                className="w-full object-cover lg:rounded"
                                style={{ height: "28em" }}
                            />
                        </div>

                        <div className="flex flex-col lg:flex-row lg:space-x-12">
                            <div className="px-4 lg:px-0 mt-12 text-gray-700 text-lg leading-relaxed w-full ">
                                <div className="blog-detail"
                                    dangerouslySetInnerHTML={{
                                        __html: detail?.description,
                                    }}
                                />
                            </div>
                        </div>
                </div>
            </SectionComponent>
        </>
    )
}

export default BlogDetailPage