import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { Box } from '@mui/material';


import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import 'swiper/css/autoplay';



const HomeSlider = ({ItemData}) => {

//   const ItemData = [
//     {
//         "id": 2,
//         "type": "site",
//         "title": "demo title 1",
//         "link": null,
//         "sub_title": "demo Subtitle 1",
//         "button": "button",
//         "image": "dr_path_care7aa6e9d5252ce058c23341ae462b942e.jpg",
//         "status": 1,
//         "created_at": "2022-06-29T02:35:36.000000Z",
//         "updated_at": "2023-05-03T09:48:53.000000Z",
//         "imageUrl": "https:\/\/mrkaiweb.com\/drpath_admin\/public\/uploads\/banner\/dr_path_care7aa6e9d5252ce058c23341ae462b942e.jpg"
//     },
//     {
//         "id": 4,
//         "type": "site",
//         "title": "Test1",
//         "link": null,
//         "sub_title": null,
//         "button": null,
//         "image": "dr_path_careed7b8d30236f65619d8d80b92b811cdd.jpg",
//         "status": 1,
//         "created_at": "2023-04-09T12:16:38.000000Z",
//         "updated_at": "2023-05-03T09:47:25.000000Z",
//         "imageUrl": "https:\/\/mrkaiweb.com\/drpath_admin\/public\/uploads\/banner\/dr_path_careed7b8d30236f65619d8d80b92b811cdd.jpg"
//     },
//     {
//         "id": 5,
//         "type": "site",
//         "title": null,
//         "link": null,
//         "sub_title": null,
//         "button": null,
//         "image": "dr_path_caref52dcdd76f71fe60086a5ab369c2dc2f.jpg",
//         "status": 1,
//         "created_at": "2023-05-03T09:54:57.000000Z",
//         "updated_at": "2023-05-03T09:54:57.000000Z",
//         "imageUrl": "https:\/\/mrkaiweb.com\/drpath_admin\/public\/uploads\/banner\/dr_path_caref52dcdd76f71fe60086a5ab369c2dc2f.jpg"
//     }
// ]; 
  return (
    <>
    
        <Swiper 
           modules={[Navigation, Pagination, A11y, Autoplay]}
           spaceBetween={50}
           slidesPerView={1}
           navigation
           autoplay
           loop
           pagination={{ clickable: true }}
           scrollbar={{ draggable: false }}
           onSwiper={(swiper) => {
            // console.log(swiper)
           }}
           onSlideChange={() => {
            // console.log('slide change')
           }}
        >
          {
            ItemData && ItemData.length > 0 && ItemData.map((item, i)=>{
              return (
                <SwiperSlide key={i}>
                  <Box>
                    <img  alt={item.title || "DrPathCare"} src={item.imageUrl} />
                  </Box>
                </SwiperSlide>
              )
            })
          }
            
        </Swiper>
    </>
  )
}

export default HomeSlider