import Cookies from "js-cookie";

export const removeImageName = (imgLink, name) => {
    let imgLinkArr = imgLink.replace(name, '');
    return imgLinkArr;
}

export const isNumber = (evt) => {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
}

export const getCartListParams = {
    "source":"web",
    "type":"list",
    "for":"",
    "test_id": ""
}


export const getCrtToken = Cookies.get('token')



export const getGender = (gender) => {
    if(gender == 0){
        return "Male";
    }else
    if(gender == 1){
        return "Female"; 
    }else {
        return "Other"; 
    }
    
}