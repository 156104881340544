import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../constants/axiosInstance";
import { getCrtToken } from "../../constants/randomFunctions";


export const dashboardDataAPI = createAsyncThunk(
  'dashboardData',
  async (params = {}, thunkAPI) => {
    const response = await axiosInstance(getCrtToken).post(
      "/customer/dashboard",
      params
    )
    return response.data
  }
)

const DashboardPageSlice = createSlice({
  name: "DashboardPage",
  initialState: {
    loading: true,
    data: [],
    error: null,
    message: null,
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(dashboardDataAPI.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    })
  },
})

export const {
  getDashboardData
} = DashboardPageSlice.actions;

export default DashboardPageSlice.reducer;