import React, { useEffect, useState } from "react";
import ModalVideo from 'react-modal-video'



const VideoModel = (props) => {
  const {isOpen, setOpen, ytCode} = props;
  useEffect(() => {
    console.log('props ???? ', props)
  }, [props])
  

  return (
    <React.Fragment>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId={ytCode}
        onClose={() => setOpen(false)}
      />
{/* 
      <button className="btn-primary" onClick={() => setOpen(true)}>
        VIEW DEMO
      </button> */}
    </React.Fragment>
  );
};

export default VideoModel;
