import axios from 'axios'
import React, { useCallback, useEffect } from 'react'
import { useState } from 'react';
// import useRazorpay from 'react-razorpay'


const RazorpayComponent = () => {
    const [PayDataRes, setPayDataRes] = useState("");
    const [OrderId, setOrderId] = useState("");

    const handlePayment = async (e)  => {
        await axios.get('https://mrkaiweb.com/drpath_admin/public/api/pay-data-api')
        .then(res=>{
            setPayDataRes(res.data)
            setOrderId(res.data.order_id);

            step2(res.data)
            // console.log('APT res >>>> ', res)
        }).catch(err=>{
            console.log('err >>> ', err)
        })
    }
    let key = "rzp_test_N3hqgRWX2ZU8Ts";
    const step2 = (order) => {
        const options = {
            key: key,
            name: "mmantratech",
            currency: 'INR',
            amount: order.amount,
            order_id: order.order_id,
            description: "Understanding RazorPay Integration",
            // image: logoBase64,
            handler: async function (response) {
              console.log('response >>>> ', response);
              headers:
              await axios.post("https://mrkaiweb.com/drpath_admin/public/api/verify-pay-data-api", {
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_order_id: response.razorpay_order_id,
                razorpay_signature: response.razorpay_signature,
              }).then((t) => {
                console.log('verify t >>> ', t)
              }).catch(e0=>console.log(' e0 >>>> ', e0));
      
            //   const data = await fetch("https://mrkaiweb.com/drpath_admin/public/api/verify-pay-data-api", {
            //     method: "POST",
            //     // headers: {
            //     //   // Authorization: 'YOUR_AUTH_HERE'
            //     // },
            //     body: JSON.stringify({
            //       razorpay_payment_id: response.razorpay_payment_id,
            //       razorpay_order_id: response.razorpay_order_id,
            //       razorpay_signature: response.razorpay_signature,
            //     }),
            //   }).then((t) => t.json());
      
            //   console.log("response==",data)
      
              // Validate payment at server - using webhooks is a better idea.
              // alert(response.razorpay_payment_id);
              // alert(response.razorpay_order_id);
              // alert(response.razorpay_signature);
            },
            prefill: {
              name: "mmantratech",
              email: "mmantratech@gmail.com",
              contact: "9773878081",
            },
          };
      
          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
      
          paymentObject.on("payment.failed", function (response) {
            alert("Payment failed. Please try again. Contact support for help");
          });
    }

    return (
        <>
            <div>OrderId : {OrderId} </div>
            <button onClick={(e) => handlePayment(e)}>Click</button>
        </>
    )
}

export default RazorpayComponent
